.c-announce {
  &-area {
    position: fixed;
    right: 20px;
    bottom: 10px;
    z-index: 50;
    &:hover {
      // cursor: pointer;
      .c-announce-bnr__txt {
        opacity: 1;
      }
    }
  }
  &-bnr {
    opacity: 0;
    transition: $transition;
    transform: translateY(100px);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    color: #ffffff;
    z-index: 10;
    font-size: 2.1rem;
    text-align: center;
    line-height: 1.4;
    @include shadowLg;
    @include mq(sp) {
      font-size: 2.6rem;
      width: 26.3vw;
      height: 26.3vw;
    }
    @at-root {
      .en .c-announce-bnr {
        font-size: 1.7rem;
        padding-bottom: 8px;
        @include mq(sp) {
          font-size: 2.3rem;
          padding-bottom: 0;
        }
      }
      .is-scroll .c-announce-bnr {
        opacity: 1;
        transform: translateY(0);
      }
    }
    .big {
      font-size: 1.47em;
      @at-root {
        .cn .c-announce-bnr .big {
          font-size: 1.3em;
        }
      }
    }
    .block-date {
      margin-top: 3px;
      font-size: .71em;
      @include boxDatePC(18);
      @include mq(sp) {
        @include boxDateSP(4);
      }
    }
    &__txt {
      opacity: 0;
      text-align: center;
      background: $colorPrimary;
      padding: 5px;
      bottom: 15px;
      z-index: 100;
      color: #ffffff;
      position: relative;
      &:before {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 5px 0 5px;
        border-color: $colorPrimary transparent transparent transparent;
      }
    }
  }
}

.c-bnr {
  padding: 30px 0;
  border-bottom: 1px solid #eeeeee;
  background-color: $gray3rd;
  @include mq(sp) {
    padding:  0 0 4rem 0;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 3.8rem;
    margin-right: 3.8rem;
    @include mq(pc) {
      margin: 0 -30px;
    }
    @include mq(sp) {
      justify-content: space-between;
    }
    li {
      margin: 21.5px;
      width: 255px;
      border: solid 1px $grayPrimary;
     
      @include mq(sp) {
        margin: 4rem 0 0 0;
        width: 31.5rem;
        a img{
          width: 100%;
        }
      }
    }
  }
  .c-inner {
    @include mq(sp) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.c-footer {
  &-info {
    padding: 45px 0;
    @include mq(sp) {
      padding: 4rem 0 4.4rem 0;
    }
    &__list {
      @include mq(pc) {
        display: flex;
        align-items: center;
      }
    }
    &__logo {
      flex-basis: 237px;
      padding-right: 30px;
      &-ico {
        height: 30px;
        width: 225px;
        @include mq(sp) {
          width: 47.2vw;
        }
      }
    }
    &__nav {
      padding: 0 15px;
      display: flex;
      font-weight: 700;
      @include mq(pc) {
        border-left: 1px solid #aaaaaa;
      }
      @include mq(sp) {
        margin-top: 5.13vw;
        border-left: 1px solid #aaaaaa;
        padding: 0 3.06vw;
        font-size: 2.1rem;
      }
      li {
        a {
          padding: 0 15px;
          display: block;
          @include mq(sp) {
            padding: 0 3.06vw;
          }
        }
      }
    }
  }
  &__organizer {
    margin-top: 44px;
    font-size: 1.2rem;
    color: #aaaaaa;
    @include mq(pc) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    @include mq(sp) {
      margin-top: 5.3vw;
      font-size: 2.1rem;
    }
    &-info {
      line-height: 2;
    }
  }
}