.c-header {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  &-simple {
    position: absolute;
    @include mq(pc) {
      padding-top: 20px;
    }
  }
  &-primary {
    background-color: #ffffff !important;
    opacity: 1;
    box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    @include mq(pc) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @include mq(sp) {
      @include shadowLg;
    }
  }
  &__logo {
    width: 284px;
    height: 36px;
    @include mq(sp) {
      width: 47.2vw;
      height: 6vw;
    }
  }
  &-info {
    @include mq(pc) {
      padding-left: 27px;
    }
    @include mq(sp) {
      padding: 0 0 0 4vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @at-root {
        .c-header-gnavi .c-header-info {
          @include mq(sp) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      .hover-opacity {
        padding-top: 1rem;
      }
    }
  }
  &-menu {
    width: 6.25vw;
    height: 4.4vw;
    &-box {
      @include mq(sp) {
        background-color: $colorSecondary;
        padding: 5.3vw;
      }
    }
    i {
      position: relative;
      left: 0;
      top: 2.13vw;
      display: block;
      background: #ffffff;
      height: 2px;
      width: 100%;
      transition: .5s;
      &:before,&:after {
        content: "";
        height: 2px;
        width: 100%;
        display: block;
        background: #ffffff;
        position: absolute;
        left: 0;
        transform: rotate(0deg);
      }
      &:before {
        transform: translateY(2.13vw);
      }
      &:after {
        transform: translateY(-2.13vw);
      }
    }
  }
  &-gnavi {
    font-weight: 700;
    @include mq(pc) {
      font-size: 1.4rem;
    }
    @include mq(sp) {
      transform: translateX(100%);
      transition: $transition;
      padding: 4vw 8vw 10vw;
      position: fixed;
      @include vw_width(628);
      height: calc(100vh - 15vw);
      top: 15vw;
      right: -1px;
      background: rgba($colorSecondary, .95);
    }
    @at-root {
      body.is-open {
        .c-header-gnavi {
          transform: translateX(0);
          @include mq(sp) {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
          }
        }
        .c-header-menu {
          &._close {
            i {
              background: none;
              &:before {
                transform: translateY(0) rotate(45deg);
              }
              &:after {
                transform: translateY(0) rotate(-45deg);
              }
            }
          }
        }
      }
      .p-top {
        .c-header-gnavi {
          @include mq(pc) {
            background: rgba(#ffffff,.75);
            padding-left: 0.52vw;
            padding-right: 0.52vw;
            margin-top: 15px;
            @include shadowLg;
            border-radius: 18px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;
            align-items: center;
          }
          &__link {
            @include mq(pc) {//トップページ：スクロール前
              padding-top: 34px;
              padding-bottom: 34px;
            }
          }
        }
        .c-header__logo {
          @include mq(pc) {
            width: 379px;
            height: 48px;
          }
        }
        .c-header-gnavi {
          @include mq(pc) {
            background: none;
          }
          &__link {//トップページ：スクロール後　設定上書き
            @include mq(pc) {
              padding-top: 29px;
              padding-bottom: 29px;
            }
          }
        }
        .c-header__logo {
          @include mq(pc) {
            width: 284px;
            height: 36px;
          }
        }
      }
      .lower {
        .c-header-gnavi {
          @include mq(pc) {
            border-radius: 10px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;
            align-items: center;
          }
        }
      }
      .c-header {
        .c-header-gnavi {
          margin-top: 0;
        }
      }
    }
    &__list {
      @include mq(pc) {
        display: flex;
      }
      @include mq(sp) {
        font-size: 3.2rem;
        color: #ffffff;
      }
    }
    &__link {
      display: block;
      padding: 29px 1.04vw;
      @include mq(pc) {
        &._ico-arrow--right {
          &:before {
            display: none;
          }
        }
      }
      @include mq(sp) {
        position: relative;
        color: #ffffff;
        padding: 2vw 2vw;
        &.icon-plus {
          &::before {
            content: "";
            background: url(../img/plus-icon.png) no-repeat ;
            background-size: contain;
            width: 4vw;
            height: 4vw;
            position: absolute;
            right: 0;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            border: 0;
            transition: transform .3s;
          }
        }
        &.is-open {
          &::before {
            transform: translateY(-50%) rotateZ(180deg);
            background: url(../img/sub-icon.png) no-repeat ;
            background-position: 50%;
            height: .4rem;
          }
          & ~ .c-mega__child {
            height: auto;
            opacity: 1;
            visibility: visible;
          }
        }
      }
      @include mq(pc) {
        &.active {
          .c-header-gnavi__link-inner {
            &:before {
              @include mq(pc) {
                @include gnav_hover-blue;
              }
            }
          }
        }
      }
      @at-root {
        .en {
          //状況にあわせてブレイクポイント個別対応
          @media screen and (min-width:768px) and (max-width:1370px) {
            .c-header-gnavi__list {
              font-size: 1.3rem;
            }
            .c-header-gnavi__link {
              padding: 29px .7vw;
            }
          }
        }
      }
      &-inner {
        position: relative;
        display: inline-block;
        text-align: center;
        border-bottom: 2px solid transparent;
      }
    }
    &__item {
      &.c-mega {
        position: static;
      }
      &:hover {
        cursor: pointer;
      }
      @include mq(sp) {
        border-bottom: 1px solid #cccccc;
        padding: 3vw 3vw 3vw 0;
      }
      @at-root {
        body:not(.ipad) {
          .c-header-gnavi__item {
            &:hover {
              @include mq(pc) {
                .c-header-gnavi__link-inner {
                  color: $colorBlue01;
                  &:before {
                    @include gnav_hover-blue;
                    color: $colorBlue01;
                  }
                }
                .c-mega__child {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
            @include mq(pc) {
              .c-mega__child-link:hover::before {
                width: 100%;
                transition: all .3s;
              }
              .c-mega__child-link::before {
                content: "";
                height: 2px;
                position: absolute;
                background-color: #ffffff;
                border: none;
                transform: none;
                bottom: 0;
                left: 0;
                top: unset;
                right: unset;
                width: 0;
                transition: all .3s;
              }
            }
          }
        }
      }
      &.active {
        .c-header-gnavi__link-inner {
          color: $colorBlue01;
          &:before {
            @include gnav_hover-blue;
            color: $colorBlue01;
          }
        }
        .c-mega__child {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .c-header-btn {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &-secondary {
    display: none;
  }
  @at-root {
    .p-top {
      .c-header {
        .c-header-primary {
          background: rgba(#fff,1);
        }
        .c-header-secondary {
          display: block;
        }
        .c-header-gnavi {
          box-shadow: none;
          // background: none;
        }
      }
    }
    .lower {
      .c-header {
          .c-header-primary {
            @include mq(pc) {
              background: rgba(#fff,.9);
            }
          }
          .c-header-secondary {
            display: block;
          }
      }
    }
    .is-open {
      .c-header-secondary {
        display: none !important;
      }
    }
  }
  &-lang {
    @include mq(sp) {
      margin-bottom: 30vw;
    }
    @include mq(pc) {
      position: relative;
      display: block;
      &:hover {
        .c-header-lang__list {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &__link {
      @include mq(pc) {
        padding: 35px 20px;
      }
      &-inner {
        padding: 0 17px 0 25px;
        position: relative;
        @include mq(pc) {
          display: inline-block;
        }
        &:before,&:after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        &:before {
          background: url(../img/ico_lang_01.png) no-repeat 0 0;
          left: 0;
          width: 20px;
          height: 20px;
        }
        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 5px 0 5px;
          border-color: $colorSecondary transparent transparent transparent;
          right: 0;
        }
      }
    }
    &__list {
      font-size: 1.3rem;
      @include mq(pc) {
        @include shadowLg;
        background: #ffffff;
        border-radius: 10px;
        transform: translateX(-50%);
        width: 120px;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: calc(100% + 14px);
        z-index: 100;
      }
      @include mq(sp) {
        font-size: 2.4rem;
        margin: 5rem -4vw 0;
        position: relative;
        display: flex;
        color: #fff;
        li {
          margin: 0 3vw !important;
        }
        li[aria-selected="true"] {
          border: 0 !important;
        }
        a {
          color: #ffffff !important;
          font-weight: normal;
          font-style: italic;
        }
        li[aria-selected="true"] a {
          font-weight: bold;
        }
      }
      &:before {
        content: "";
        position: absolute;
        @include mq(pc) {
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-style: solid;
          border-width: 0 7.5px 8px 7.5px;
          border-color: transparent transparent #fff transparent;
          filter: drop-shadow(6px 5px 9px rgba(4, 0, 0, .5));//ieどうするか
        }
        @include mq(sp) {
          display: none;
        }
      }
      li {
        @include mq(pc) {
          border-bottom: 1px solid #aaaaaa;
        }
        @include mq(sp) {
          margin: 0 4vw;
        }
        &:last-child {
          @include mq(pc) {
            border-bottom: none;
          }
        }
        &[aria-selected="true"] {
          @include mq(sp) {
            border-bottom: 2px solid $colorSecondary;
          }
          a {
            @include mq(sp) {
              color: $colorSecondary;
            }
            &:after {
              background: $colorSecondary;
            }
          }
        }
        &:hover {
          // border-bottom: 1px solid #aaaaaa;
          a {
            &:after {
              background: $colorSecondary;
            }
          }
        }
      }
      a {
        position: relative;
        display: block;
        padding: 12px 10px;
        @include mq(sp) {
          color: rgba($colorSecondary,.75);
          padding: 0;
        }
        &:after {
          @include mq(pc) {
            content: "";
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background: #cccccc;
          }
        }
      }
    }
  }
  &-btn {
    @include mq(sp) {
      margin: 40 / (750 / 100vw) 2vw 0;
    }
    .c-btn-exclusive {
      color: #ffffff;
      display: flex;
      align-items: center;
      width: 60px;
      z-index: 100;
      @include mq(pc) {
        position: fixed;
        right: 0;
        top: 184 / (1920 / 100vw);
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 34px 0 60px;
        p {
          letter-spacing: 4px;
        }
      }
      @include mq('laptop') {
        top: 15vw;
      }
      &::before {
        content: '';
        background: url(../img/icon-login-white.png) no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 24px;
        width: 24px;
        height: 24px;
        @include mq(pc) {
          left: 50%;
          transform: translateX(-50%);
        }
        @include mq(sp) {
          background: url(../img/login.png) no-repeat;
          background-size: contain;
          right: 6vw;
          width: 5vw;
          height: 5vw;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      @include mq(sp) {
        color: $colorSecondary;
        justify-content: center;
        height: 90 / (750 /100vw);
        font-size: 3rem;
        width: 100%;
        margin: 40 / (750 / 100vw) auto 0;
        border-radius: 4rem;
        background-color: #ffffff;
        position: relative;
      }
      &:hover {
        opacity: 0.9;
      }
      &__ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #ffffff;
        margin-bottom: 8px;
        svg {
          width: 20px;
          height: 20px;
        }
        @include mq(sp) {
          display: none;
        }
      }
    }
    .c-btn-apply {
      z-index: 100;
      @include mq(pc) {
        color: $colorPrimary;
        position: fixed;
        bottom: 30px;
        right: 0;
      }
      &__catch {
        text-align: center;
        font-size: 1.8rem;
        margin-bottom: 5px;
        text-shadow: 2px -1px 3px #fff;
        @include mq(sp) {
          font-size: 2.4rem;
        }
      }
      &__btn {
        display: flex;
        align-items: center;
        border-right: none;
        font-size: 3.2rem;
        margin: 0 auto;
        @include mq(pc) {
          border: solid 4px $colorPrimary;
          background: #ffffff;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          transition: $transition;
          width: 356px;
          height: 79px;
          padding: {
            left: 30px;
          }
        }
        @include mq(sp) {
          background: $colorPrimary;
          color: #ffffff;
          height: 90 / (750 /100vw);
          justify-content: center;
          font-size: 3rem;
          width: 100%;
        }
        &:hover {
          background: $colorPrimary;
          color: #ffffff;
          &:after {
            border-color: transparent transparent transparent #fff;
          }
        }
        @at-root {
          .ja,.cn {
            .c-btn-apply__btn {
              position: relative;
              &:after {
                content:"";
                position: absolute;
                right: 26px;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 0 8px 11px;
                border-color: transparent transparent transparent $colorPrimary;
              }
            }
          }
          .cn {
            .c-btn-apply__btn {
              @include mq(pc) {
                width: 298px;
              }
            }
          }
          .en {
            .c-btn-apply__btn {
              flex-direction: column;
              padding-left: 10px;
              @include mq(pc) {
                width: 382px;
              }
              &:hover {
                background: #fff;
                color: inherit;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.c-mega {
  position: relative;
  &__child {
    z-index: 100;
    width: auto;
    white-space: nowrap;
    text-align: center;
    bottom: -60px;
    width: 100vw;
    left: 0;
    transition: all .3s;
    position: absolute;
    @include mq(pc) {
      visibility: hidden;
      top: auto;
    }
    @include mq(sp) {
      height: 0;
      opacity: 0;
      overflow: hidden;
      position: relative;
      top: 0 !important;
      text-align: left;
    }
    &-link {
      display: block;
      padding: 6px 0 3px;
      color: #ffffff;
      position: relative;
      transition: all 0s;
      @include mq(sp) {
        padding: 1vw 2vw;
        font-size: 2.4rem;
        display: flex;
      }
    }
    &-list {
      padding: 15px 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include mq(pc) {
        @include shadowSm;
        background: $colorBlue01;
      }
      @include mq(sp) {
        padding: 0;
        font-weight: normal;
        flex-direction: column;
      }
    }
    &-item {
      position: relative;
      @include mq(pc) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include mq(sp) {
        white-space: normal;
      }
      &:not(:last-child) {
        span {
          @include mq(pc) {
            color: $colorBlue02;
            padding: 0 30px;
          }
        }
      }
    }
  }
}

.ja {
  .c-btn-apply__btn {
    flex-direction: column;
    justify-content: center;
    font-size: 2rem;
    @include mq(pc) {
      padding-left: 0;
    }
    @include mq(sp) {
      font-size: 2.6rem;
    }
  }
  @include mq(sp) {
    .c-btn-apply__catch {
      // width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }

}

.en {
  @include mq(pc) {
    .c-header-btn .c-btn-exclusive p {
      letter-spacing: 1px;
    }
  }
}