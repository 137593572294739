.p-press {
    @include mq(pc) {
        .c-lower-hero__heading {
            font-size: 40px;
        }
    }
}
.press-page {
    padding: 80px 0 120px;
    @include mq(sp) {
        padding: 5rem 0 12rem;
    }
    .bold {
        font-weight: bold;
    }
    .c-inner {
        @include mq(pc) {
            padding-left: 70px;
            padding-right: 70px;
        }
        @include mq(sp) {
            padding-left: 4.2vw;
            padding-right: 5.5vw;
        }
    }
    .c-head-with-border {
        display: flex;
        align-items: center;
        font-size: 2.7rem;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 2px solid #000;
        @include mq(sp) {
            font-size: 3.2rem;
            margin-bottom: 3.7vw;
            padding-bottom: 1.5vw;
            letter-spacing: 0.2vw;
        }
    }
    .press {
        &__title {
            background-color: $bluePrimary;
            padding: 23.5px 0;
            position: relative;
            margin-bottom: 34px;
            @include mq(sp) {
                padding: 3.4rem 0;
                margin-bottom: 3rem;
            }
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: url(../img/press/bg-title.png) no-repeat;
                background-size: 100% 100%;
                top: 0;
                right: 0;
                z-index: 0;
            }
            p {
                text-align: center;
                font-size: 24px;
                font-weight: bold;
                color: #fff;
                position: relative;
                z-index: 1;
                @include mq(sp) {
                    font-size: 2.4rem;
                }
            }
            &-text {
                font-size: 17px;
                margin-bottom: 54px;
                font-weight: bold;
                @include mq(sp) {
                    font-size: 2rem;
                    margin-bottom: 5.6rem;
                }
            }
        }
        &__sub-title {
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            padding: 10px 14px;
            background-color: $visit_BgSecondary;
            @include mq(sp) {
                font-size: 2.4rem;
                padding: 1.8rem 1.8rem;
                margin-bottom: 2.3rem;
            }
        }
        &__sub-text {
            color: #222222;
            line-height: 1.75;
            @include mq(sp) {
                font-size: 1.8rem;
            }
            &:not(:last-child) {
                margin-bottom: 40px;
                @include mq(sp) {
                    margin-bottom: 4.5rem;
                }
            }
            a {
                color: $visit_BgSecondary;
            }
        }
    }
}