.sem-about-list {
    &__wapper{
        margin-bottom: 43px;
        @include mq(sp) {
            margin-bottom: 4rem;
        }
    }
    &__content{
        justify-content: space-between;
        &:nth-child(2){
            margin-top: 42px;
            @include mq(sp) {
                margin-top: 3.1rem;
            }
        }
    }
    &__item{
        @include mq(sp){
            width: 32.1rem;
            &:nth-child(1){
                margin-right: 3.2rem;
            }
        }
        p{
            font-size:2rem;
            margin-top: 22px;
            @include mq(sp) {
                font-size: 1.7rem;
                margin-top: 2.3rem;
            }
        }
    }
    &__box-content{
        justify-content:space-between;
        margin-top: 4rem;
        @include mq(sp) {
            flex-direction: column;
        }
    }
    &__box-item{
        padding: 32px 22px 38px 22px;
        width: 333px;
        height: 280px;
        @include mq(sp) {
            display:flex;
            width: 100%;
            height: 100%;
            padding: 3.2rem 0 3.2rem 5.8rem;
        }
        p{
            letter-spacing: normal;
            &:nth-child(n+4){
                font-size: 1.3rem;
            }
        }
    }
    &__box-left{
        @include mq(sp) {
            padding-right: 5.7rem;
            border-right: 1px solid $pre_gray;
            line-height:1.2;
        }
    }
    &__box-right{
        @include mq(sp) {
            margin-left: 2.5rem;
            line-height:1.6;
        }

    }
}
.sem-about{
    &-custom-left{
        font-size:1.7rem;
    }
    &-custom-pl{
        padding-left: 6px;
        @include mq(sp){
            padding-left: 0.4rem;
        }
    }
    &-box__text{
        font-size:1.3rem;
    }
    &-box__text1{
        font-size: 1.3rem;
        @include mq(sp){
            text-align: center;
        }
    }
    &-cus-fonts{
        font-size: 1.7rem;
    }
    &-title{
        margin-bottom: 4rem;
    }
    &-title-top{
        @include mq(sp) {
            margin-top: 7.6rem;
        }
    }
    &-box{
        padding-left: 41px;
        padding-right: 52px;
    }
    &-footer{
        margin-bottom: 120px;
        @include mq(sp) {
            margin-bottom: 8rem;
        }
    }
    &-text-01{
        font-size: 1.7rem;
    }
    &-text-02{
        font-size: 4rem;
    }
    &-text-03{
        font-size: 2rem;
        padding-bottom: 22px;
        text-align: center;
        @include mq(sp) {
            padding-bottom: 0;
            text-align: left;
        }
    }
    &-text-04{
        border-top: 1px solid $pre_gray;
        padding-top: 25px;
        font-size: 1.3rem;
        text-align: center;
        @include mq(sp){
            border-top: none;
            padding-top: 0;
            text-align: left;
            font-size: 1.7rem;
        }
    }
    &-text-05{
        font-size: 1.3rem;
    }
    &-picture{
        img{
            @include mq(sp){
                width: 1.3rem;
            }
        }
        @include mq(sp){
            padding-top: 2.2rem;
            padding-bottom: 2.2rem;
            transform: rotate(90deg);
        }
    }
}
