@charset "utf-8";
@mixin letter-spacing($tracking) {
  letter-spacing: $tracking / 1000 + em;
}
$baseTracking: .06em;
$transition: .3s;
$spDesign: 750 / 100;
$gradaMix: linear-gradient(160deg, #47bbaa, #429fd7 26%, #f9d83a 52%, #ee9f0c 78%, #ec6960);
$opacity: .75;
$breakpoints: (
  'pc': 'screen and (min-width: 768px)',//pc
  'sp': 'screen and (max-width: 767px)',//sp
  'laptop': 'screen and (max-width: 1280px) and (min-width:768px)',
  'kv': 'screen and (max-width: 1366px) and (min-width:768px)'
) !default;

$colorParagraph:#333333;
$colorPrimary:#f92157;
$colorSecondary:#197cd4;
$colorAccent:#01294e;
$colorbackground:#fff;
$colortxtGray:#aaaaaa;
$colorExtra: #222222;
$colorExtraDark30: #181818;
$colorExtraLight30: #646464;
$colorExtraLight80: #d3d3d3;
$btnCOlor:#a7a7a7;
$blueRight:#0275db;
$bluePrimary:#1266af;
$redRight:#d40000;
$gray: #ccc;
$searchColor:#eee;
$colorBlue01:#2278cf;
$colorBlue02:#1a4f85;
$colorBlue03:#3291e4;
$colorBlue04:#5ba7e9;
$colorBlue05:#1e81d5;
$gray3rd:#f7f7f7;
$colorOrange01: #e8750f;
$colorRed01: #d20d41;
$colorBlue06: #0943c6;
$colorBlue07: #2886d8;
$colorBlueLight01: #2ca5ea;
$colorGreen01: #099312;


$grayPrimary:#eeeeee;
$visit_BgPrimary:#045499;
$visit_BgSecondary:#1e81d5;
$pre_gray:#cccccc;
$graySecondary: #595959;
$gray4th: #e6e6e6;
$colorWhite:#fff;

$alls: (
  colorParagraph:#333333,
  colorPrimary:#f92157,
  colorSecondary:#197cd4,
  colorAccent:#01294e,
  colorWhite:#fff,
  txtGray:#aaaaaa,
  secondary: #197cd4,
  accent: #01294e,
  extra: #222222,
  btnGray:#a7a7a7,
 );

$colors: (
  colorParagraph:#333333,
  colorPrimary:#f92157,
  colorSecondary:#197cd4,
  colorAccent:#01294e,
  colorWhite:#fff,
  txtGray:#aaaaaa,
  btnGray:#a7a7a7,
  extra: #222222,
  gray: #ccc,
  blueRight:#0275db,
  bluePrimary:#1266af,
  colorBlue03:#3291e4,
  colorBlue05:#1e81d5,
  redRight:#d40000,
  grayPrimary: #eeeeee,
  gray3rd:#f7f7f7,
  redPrimary:#e5000c,
  redSecond:#e90014,
  red3rd:#e60010,
  red4th:#e9343e,
  colorOranges:#f49320,
);

$primary: (
  primary: #f92157,
  primaryDark30: #af173d,
  primaryLight30: #fb6389,
  primaryLight80: #fed3dd,
);

$secondary: (
  secondary: #197cd4,
  secondaryDark30: #125795,
  secondaryLight30: #5ea3e1,
  secondaryLight80: #d1e5f6,
);

$accent: (
  accent: #01294e,
  accentDark30: #011d37,
  accentLight30: #4d6983,
  accentLight80: #ccd4dc,
);

// $extra: (
//   extra: #222222,
//   extraDark30: #181818,
//   extraLight30: #646464,
//   extraLight80: #d3d3d3,
// );

@function primary($key) {
  @return map-get($primary,$key); //color:primary(primaryLight80);
}

@function secondary($key) {
  @return map-get($secondary,$key); //color:primary(secondaryLight80);
}

@function accent($key) {
  @return map-get($accent,$key); //color:primary(accentLight80);
}

@each $name,$color in $colors {
  ._c-#{$name} {
    color: $color!important;
  }
  ._border-#{$name} {
    border: 3px $color solid;
  }
  ._border2px-#{$name} {
    border: 2px $color solid;
  }
  ._border1px-#{$name} {
    border: 1px $color solid;
  }
  ._bg-#{$name} {
    background-color: $color;
  }
  .fill-#{$name} {
    fill: $color;
  }
}

@each $name,$all in $alls {
  ._hover-#{$name} {
    &:hover {
      background: $all;
    }
  }
  ._hover-color-#{$name} {
    &:hover {
      color: $all !important;
    }
  }

}

//Paragraph color
$colorParagraphM30: darken($colorParagraph,30%);
$colorParagraphP30: lighten($colorParagraph,30%);
$colorParagraphPastel: lighten($colorParagraph,80%);

//background color
$colorbackgroundM30: darken($colorbackground,30%);


// .colorPrimaryM30 {
//   color: $colorPrimaryM30;
// }
// .colorPrimaryP30 {
//   color: $colorPrimaryP30;
// }
// .colorPrimaryPastel {
//   color: $colorPrimaryPastel;
// }
// .colorSecondaryM30 {
//   color: $colorSecondaryM30;
// }
// .colorSecondaryP30 {
//   color: $colorSecondaryP30;
// }
// .colorSecondaryPastel {
//   color: $colorSecondaryPastel;
// }
// .colorAccentM30 {
//   color: $colorAccentM30;
// }
// .colorAccentP30 {
//   color: $colorAccentP30;
// }
// .colorAccentPastel {
//   color: $colorAccentPastel;
// }
// .colorParagraphM30 {
//   color: $colorParagraphM30;
// }
// .colorParagraphP30 {
//   color: $colorParagraphP30;
// }
// .colorParagraphPastel {
//   color: $colorParagraphPastel;
// }
// .colorbackgroundM30 {
//   color: $colorbackgroundM30;
// }