

.p-preview {
  &-sec {
    margin-top: 40px;
    &__inner {
      padding: {
        left: 20px;
        right: 20px;
      }
    }
  }
  .c-heading-hasbox {
    margin-bottom: 30px;
  }
  &-intro {
    margin-top: 40px;
    padding: 20px;
    text-align: center;
    background: #eeeeee;
    font-size: 1.2rem;
  }
  &-card {
    padding: 24px 20px 40px;
    border: 1px solid #cccccc;
    &:nth-child(n+2) {
      margin-top: 13px;
    }
    &__header {
      margin-bottom: 24px;
      padding-bottom: 20px;
      color: $blueRight;
      border-bottom: 1px solid #cccccc;
      @include mq(pc) {
        font-size: 1.8rem;
      }
    }
    &__list {
      display: flex;
      margin-bottom: 20px;
      &-ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 25px;
        font-size: 1.2rem;
        &:nth-child(n+2) {
          margin-left: 8px;
        }
        &--new {
          @extend .p-preview-card__list-ico;
          color: #ffffff;
          background: #00b285;
        }
        &--demo {
          @extend .p-preview-card__list-ico;
          color: #00b285;
          border: 1px solid #00b285;
        }
      }
    }
    &__body {
      display: flex;
      @include mq(pc) {
        p {
          width: 78.67%;
        }
      }
      @include mq(sp) {
        flex-direction: column;
      }
      * {
        min-width: 80px;
      }
    }
    &__img {
      @include mq(pc) {
        flex-shrink: 0;
        margin-left: auto;
      }
      @include mq(sp) {
        margin-bottom: 20px;
        order: -1;
        text-align: center;
      }

    }
  }
  &__btn {
    margin: 90px auto 0;
  }
}
