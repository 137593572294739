.c-tab {
  &-controller {
    &__list {
      display: flex;
    }
    &__item {
      width: calc(100% / 3);
      &:last-child {
        .c-tab-controller__btn {
          border-top-right-radius: 15px;
        }
      }
      &:first-child {
        .c-tab-controller__btn {
          border-top-left-radius: 15px;
        }
      }
      &:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }
    &__btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      font-size: 2.7rem;
      height: 80px;
      font-weight: bold;
      background: #d1d1d1;
      font-family: inherit;
      color: inherit;
      @include mq(sp) {
        height: 60px;
        font-size: 2.3rem;
      }
      &[aria-selected="true"] {
        background: $colorAccent;
        color: #ffffff;
      }
    }
  }
  &-panel {
    &__item {
      &[aria-hidden="true"] {
        display: none;
      }
    }
  }
}