@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin shadowLg-x {
  box-shadow: 0 0 6px 6px rgba(85, 85, 85, .1);//グロナビとか
}
@mixin shadowLg {
  box-shadow: 0 0 3px 1px rgba(85, 85, 85, .1);//グロナビとか
}
@mixin shadowSm {
  box-shadow: 0 0 15px 0 rgba(85, 85, 85, .25);
}

@mixin boxDatePC($size) {
  display: flex;
  width: $size + px;
  height: $size + px;
  align-items: center;
  justify-content: center;
}

@mixin boxDateSP($size) {
  display: flex;
  width: $size + vw;
  height: $size + vw;
  align-items: center;
  justify-content: center;
}

@mixin vw_width($width) {
  width: $width /  (750 / 100) + vw;
}

@mixin arrowRight($size,$width,$color) {
  content:"";
  position: absolute;
  top: 50%;
  right: $size;
  width: $size;
  height: $size;
  border-top: $width + px solid $color;
  border-right: $width + px solid $color;
  transform: rotate(45deg) translateY(-50%);
}

@mixin fb_width($width) {
  flex-basis: $width;
  max-width: $width;
}

@mixin font_ja {
  font-family:'Noto Sans JP', sans-serif;
}

@mixin gnav_hover {
  content: "";
  position: absolute;
  background: $colorPrimary;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

@mixin gnav_hover-blue {
  @include gnav_hover;
  background: $colorBlue01;
}

@function Rem($size) {
  @return $size / 10px * 1rem;
}
