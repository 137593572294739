.c-btn {
  &-basic {
    border-radius: 30px;
    font-size: 1.8rem;
    line-height: 1.3;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
      width: 78.6vw;
      height: 12vw;
      font-size: 3rem;
      margin-left: auto;
      margin-right: auto;
    }
    &.btn-en {
      @include mq(pc) {
        font-size: 1.5rem;
      }
    }
    &:hover {
      opacity: 1;
    }
    ._ico-dl {
      width: 27px;
      height: 24px;
      margin-right: 15px;
      @include mq(sp) {
        width: 37 / $spDesign + vw;
        height: 33 / $spDesign + vw;
        margin-right: 2.6vw;
      }
    }
    &__sm {
      @extend .c-btn-basic;
      flex-shrink: 0;
      font-weight: 500;
      @include mq(pc) {
        max-width: 280px;
        flex-basis: 280px;
      }
    }
    &__mid {
      @extend .c-btn-basic;
      @include mq(pc) {
        max-width: 600px;
        flex-basis: 600px;
      }
    }
    &__lg {
      &-x {
        font-weight: 700;
        @extend .c-btn-basic;
        max-width: 1200px;
        width: 100%;
        height: 123px;
        font-size: 3.6rem;
        border-radius: 60px;
        @include mq(sp) {
          font-size: 2.7rem;
          width: 92vw;
          height: 13.6vw;
        }
      }
    }
    &:nth-child(n+2) {
      @include mq(sp) {
        margin-top: 5.3vw;
      }
    }
    &._bg-colorSecondary {
      &:hover {
        background: secondary(secondaryLight30);
        opacity: 1;
      }
    }
    &._bg-colorPrimary {
      &:hover {
        background: primary(primaryLight30);
        opacity: 1;
      }
    }
    .glasses {
      margin-right: 10px;
    }
  }
  &-list {
    @include mq(pc) {
      display: flex;
      // flex-wrap: wrap;
    }
    .c-btn-basic__sm {
      &:nth-child(2) {
        @include mq(pc) {
          margin-left: 40px;
        }
      }
    }
  }
}

._border-colorPrimary {
  @include mq(sp) {
    border-width: 2px;
  }
}

._ico-arrow {
  position: relative;
  &--right {
    @extend ._ico-arrow;
    &:before {
      @include arrowRight(1.86vw,1,$colorParagraph);
      right: 3vw;
      // content:"";
      // position: absolute;
      // top: 50%;
      // right: 0;
      // width: 1.86vw;
      // height: 1.86vw;
      // border-top: 1px solid $colorParagraph;
      // border-right: 1px solid $colorParagraph;
      // transform: rotate(45deg) translateY(-50%);
    }
  }
}