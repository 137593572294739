.sem{
    @at-root {
        .p-seminar {
            .v-btn {
                @include mq(sp) {
                    padding-left: 0 !important;
                }
            }
        }
    }
    .c-visit-wrap__container {
        @include mq(pc) {
            margin-bottom: 7.9rem !important;
        }
        @include mq(sp) {
            margin-bottom: 11.5rem !important;
        }
    }
    .sem-table{
        &__wapper{
            border-radius: 9px;
            overflow: hidden;
            margin-top: 40px;
            @include mq(sp) {
                margin-top: 4rem;
                border-radius: .9rem;
            }
        }
        &__list{
            @include mq(sp) {
                flex-direction: column;
            }
        }
        &__title {
            font-size: 2.4rem;
            text-align: center;
            padding-top: 1.9rem;
            padding-bottom: 1.9rem;
            line-height: 1;
            &:not(:last-child){
                border-right: 2px solid $colorBlue04;
                @include mq(sp){
                    border-right: 0px solid transparent;
                }
            }
            @include mq(sp){
                font-size:3.2rem;
                letter-spacing: 1.92px;
            }
        }
        &__item{
            background-color: $gray3rd;
            width: 361px;
            &:not(:last-child){
                @include mq(sp) {
                    margin-bottom: 4rem;
                }
            }
            @include mq(sp){
                width: auto;
                border-radius:8px;
                overflow: hidden;
            }
        }
        &__content{
            padding-left: 15px;
            padding-right: 15px;
            @include mq(sp) {
                padding-left: 0;
                padding-right: 0;
            }
            li{
                border-radius: 8px;
                background-color:$colorWhite;
                padding: 15px 0 16px 16px;
                box-shadow: 2px 3.5px 3.8px 0.2px rgba(0, 0, 0, 0.08);
                cursor: pointer;
                &:hover {
                    opacity: .85;
                }
                @include mq(sp) {
                    border-radius: .8rem;
                    padding: 1.8rem 2.5rem;
                    box-shadow: .2rem .35rem .38rem 0.02rem rgba(0, 0, 0, 0.08);
                }
                &:not(:last-child){
                    margin-bottom: 12px;
                    @include mq(sp) {
                        margin-bottom: 1.4rem;
                    }
                }
                p:nth-child(1){
                    font-size:1.5rem;
                    font-weight: bold;
                    @include mq(sp){
                        font-size: 2rem;
                    }
                }
                p:nth-child(2){
                    display: inline-block;
                    font-size: 1.1rem;
                    padding: 6px;
                    line-height:1;
                    margin-top: 7px;
                    @include mq(sp) {
                        font-size: 1.63rem;
                        padding: 0.9rem 1.2rem;
                        margin-top: 1.4rem;
                    }
                }
            }
            a {
                &:not(:last-child){
                    li {
                        margin-bottom: 13px;
                        @include mq(sp) {
                            margin-bottom: 2.7rem;
                        }
                    }
                }
            }
        }
        &__content-1st{
            margin-top: 32px;
            margin-bottom: 36px;
            @include mq(sp) {
                margin: 3.2rem;
            }

        }
        &--margin-left{
            margin-left: 9px;
            @include mq(sp) {
                margin-left: 3.2rem;
            }
        }
        &--margin-right{
            margin-right: 9px;
            @include mq(sp) {
                margin-right: 3.2rem;
            }

        }
    }
    .sem-discription{
        font-size: 1.7rem;
        padding-top: 31px;
        padding-bottom: 38px;
        @include mq(sp) {
            padding-top:4.4rem;
            padding-bottom: 3.9rem;
            font-size: 2.4rem;
        }
        &--1st{
            margin-top: 2.3rem;
            font-size: 1.4rem;
            margin-bottom: 40px;
            @include mq(sp){
                font-size: 1.5rem;
            }
        }
    }
    .sem-cus-font{
        font-size:2.4rem !important;
        &--pc17-20{
            font-size:1.7rem !important;
            @include mq(sp){
                font-size:2rem !important;
            }
        }
        &--pc17-17{
            font-size:1.7rem !important;
        }
    }
    .v-btn {
        position: relative;
        &--group {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
    .v-btn--text {
        font-size: 13px;
        text-align: center;
        margin-top: 15px;
        @include mq(sp) {
            font-size: 1.5rem;
            margin-top: 2.3rem;
        }
        &.sp-17 {
            @include mq(sp) {   
                font-size: 1.7rem;
            }
        }
    }
}
.sem02-table{
    margin-top: 40px;
    margin-bottom: 75px;
    line-height:1;
    @include mq(sp) {
        margin-top: 4.5rem;
        margin-bottom: 7.5rem;
    }
    &__wapper{
        margin-bottom: 8rem;
        border-radius: 9px;
        overflow: hidden;
    }
    &__content{
        padding-left: 12px;
        padding-right: 9px;
        @include mq(sp) {
            padding-left: 0px;
            padding-right: 0px;
        }
        li{
            border-radius: 4px;
            background-color: $colorWhite;
            box-shadow: 2px 3.5px 3.8px 0.2px rgba(0, 0, 0, 0.08);
            -webkit-box-shadow: 2px 3.5px 3.8px 0.2px rgba(0, 0, 0, 0.08);
            -moz-box-shadow: 2px 3.5px 3.8px 0.2px rgba(0, 0, 0, 0.08);
            border-collapse: separate;
            cursor: pointer;
            transition: opacity .3s ease;
            &:hover {
                opacity: 0.85;
            }
            &:not(:last-child){
                margin-bottom: 13px;
                @include mq(sp) {
                    margin-bottom: 2.7rem;
                }
            }
            .content {
                padding: 11px 11px 14px 12px;
            }
            &.cell {
                border: 1px solid $colorOrange01;
                overflow: hidden;
                &--orange {
                    border-color: $colorOrange01;
                    .title {
                        background-color: $colorOrange01;
                    }
                }
                &--blue {
                    border-color: $colorBlue06;
                    .title {
                        background-color: $colorBlue06;
                    }
                }
                &--blue02 {
                    border-color: $colorBlue07;
                    .title {
                        background-color: $colorBlue07;
                    }
                }
                &--blue03 {
                    border-color: $colorBlue07;
                    .title {
                        background-color: $colorBlue07;
                    }
                }
                &--blue-light {
                    border-color: $colorBlueLight01;
                    .title {
                        background-color: $colorBlueLight01;
                    }
                }
                &--green {
                    border-color: $colorGreen01;
                    .title {
                        background-color: $colorGreen01;
                    }
                }
                .title {
                    font-size: 13px;
                    width: 100%;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    color: #fff;
                    line-height: 1;
                    @include mq(sp) {
                        font-size: 2rem;
                        height: 5.7rem;
                    }
                }
            }
        }
        &.sem-table__content-1st {
            @include mq(pc) {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
    &--text {
        border-top: 1px solid $gray4th;
        margin-left: -16px;
        padding: 9px 0 0 16px;
        p{
            font-size: 1.2rem;
            line-height: 1.5;
            @include mq(sp) {
                font-size:1.7rem;
            }
        }
    }
    &--text-1{
        font-size: 1.1rem;
        padding: 0.6rem;
        margin-top: 5px;
        margin-bottom: 10px;
        @include mq(sp) {
            font-size: 1.63rem;
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }
    }
    &--text-2{
        font-size: 13px;
        padding-bottom: 14px;
        @include mq(sp){
            font-size:2rem;
            height: auto;
        }
    }
    &--text-3{
        font-size: 1.4rem;
    }
    &--text-4{
       @include mq(sp){
        margin-left: -6px;
       }
    }
    .reg-gr-iteam {
        div {
            &:nth-child(1) {
                @include mq(pc) {
                    padding: 22px 0 22px 24px;
                    min-width: 320px;
                }
            }
            &:nth-child(2) {
                @include mq(sp) {
                    line-height: 1.4;
                }
                p {
                    line-height: 1.5;
                }
            }
        }
    }
}
.sem02-title {
    font-size: 2.7rem;
    justify-content:space-between;
    margin-bottom: 39px;
    border-bottom: 2px solid $colorExtra;
    @include mq(sp) {
        flex-direction: column-reverse;
        margin-bottom: 3rem;
    }
    &--text {
        padding-bottom: 1.6rem;
        h3{
            margin-left: 17px;
            font-size: 2.7rem;
            @include mq(sp) {
                font-size:3.2rem;
                line-height: 1.3;
                margin-left: 1.5rem;
            }
        }
        picture{
            @include mq(sp) {
                width: 4rem;
            }
        }
    }
    &--table{
        margin-bottom: 31px;
        @include mq(sp) {
            margin-bottom: 4rem;
        }
        h3{
            font-size:3.2rem;
            @include mq(sp) {
                font-size:4rem;
            }

        }
    }
}
.sem02-list{
    @include mq(sp) {
        margin-bottom: 4.7rem;
        display: flex;
        justify-content: flex-end;
    }
    li{
        &:nth-child(1) {
            font-size: 18px;
            color: #fff;
            background-color: $colorRed01;
            width: 90px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            line-height: 1;
            @include mq(sp) {
                font-size: 2rem;
                width: 9rem;
                height: 4rem;
            }
        }
        &:not(:first-child){
            width: auto;
            height: 40px;
            @include mq(sp) {
                height: 4rem;
            }
            img {
                width: auto;
                height: 100%;
            }
        }
        &:not(:last-child){
            margin-right: 16px;
            @include mq(sp) {
                margin-right: 1.6rem;
            }
        }
    }
}
.sem02-picture {
    &--big{
        margin-left: 45px;
        width: 345px;
        min-width: 345px;

        @include mq(sp) {
            margin-left: 0;
            width: 100%;
            min-width: 100%;
        }
    }
    &--small{
        justify-content: space-between;
        img{
            @include mq(sp) {
                width: 100%;
            }
        }
        @include mq(sp) {
            flex-wrap: wrap-reverse;
        }
    }
}
.sem02-text-content{
    font-size: 1.7rem;
    line-height: 1.8;
    margin-top: -8px;
    font-weight: bold;
    @include mq(sp){
        margin-top: 4rem;
        font-size:2rem;
    }
}
.sem02-small{
    font-size:1.4rem;
    display:block;
    @include mq(sp) {
        font-size:2rem;
    }
}
.sem-cus-line-height{
    @include mq(sp) {
        line-height: 1.6;
    }
}
#seminar-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(#313131, 0.64);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &.is-open {
        opacity: 1;
        visibility: visible;
    }
    .modal {
        background-color:#fff;
        margin: auto;
        max-width: 1080px;
        width: 1080px;
        position: relative;
        @include mq(sp) {
            max-width: 90%;
            width: 90%;
            top: -10%;
        }
        &.tooLong {
            @include mq(sp) {
                .modal--text {
                    max-height: unset;
                }
                .modal-box {
                    max-height: 70vh;
                    overflow: scroll;
                }
            }
        }
        * {
            letter-spacing: 1px;
            @include mq(sp) {
                letter-spacing: .1rem;
            }
        }
        &-content {
            padding: 40px;
            padding-bottom: 32px;
            @include mq(sp) {
                padding: 2.4rem 3rem;
                padding-bottom: 3.3rem;
            }
        }
        &-box {
            &--type01 {
                line-height: 1;            
                font-size: 15px;
                padding: 8px 20px;
                color: #fff;
                margin-bottom: 10px;
                display: inline-block;
                &.orange {
                    background-color: $colorOrange01;
                }
                &.blue {
                    background-color: $colorBlue06;
                }
                &.blue02 {
                    background-color: $colorBlue07;
                }
                &.blue-light {
                    background-color: $colorBlueLight01;
                }
                &.green {
                    background-color: $colorGreen01;
                }
                @include mq(sp) {
                    font-size: 1.5rem;
                    padding: 0.8rem 2rem;
                    margin-bottom: 1rem;
                }
            }
            &--type02 {
                font-size: 16px;
                background-color: $colorPrimary;
                padding: 7px;
                width: 100px;
                color: #fff;
                text-align: center;
                font-weight: bold;
                @include mq(sp) {
                    font-size: 1.6rem;
                    width: 10rem;
                    padding: .8rem;
                }
            }
        }
        &--title {
            color: $bluePrimary;
            font-size: 27px;  
            font-weight: bold;
            border-bottom: 2px solid $pre_gray;
            padding-bottom: 20px;
            margin-bottom: 24px;
            @include mq(sp) {
                font-size: 2.7rem;
                padding-bottom: 1.8rem;
                border-width: .2rem;
                margin-bottom: 2.9rem;
            }
        }
        &--text {
            font-size: 15px;
            font-weight: bold;
            line-height: 1.75;
            border-bottom: 1px dotted $gray;
            padding-bottom: 28px;
            margin-bottom: 30px;
            max-height: 30vh;
            overflow-y: auto;
            &.no-border {
                border-bottom: none;
                padding-bottom: 0;
            }
            @include mq(sp) {
                border-width: .1rem;
                font-size: 2rem;
                margin-bottom: 3.2rem;
                padding-bottom: 3.2rem;
            }
        }
        .list-time {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            @include mq(sp) {
                font-size: 1.6rem;
            }
            .time {
                display: flex;
                align-items: center;
                &:not(:last-child) {
                    margin-right: 27px;
                    @include mq(sp) {
                        margin-right: 7.2rem;
                        margin-bottom: 2.2rem;
                    }
                }
                .modal-box--type02 {
                    margin-right: 18px;
                    @include mq(sp) {
                        margin-right: 2rem;
                    }
                }
                &.time02 {
                    @include mq(sp) {
                        margin-right: 0;
                    }
                }
                &-text {
                    font-weight: bold;
                }
            }
        }
        &--footer {
            background-color: $bluePrimary;
            padding: 19px 40px;
            font-weight: bold;
            color: #fff;
            @include mq(sp) {
                padding: 2rem 3.4rem;
            }
            &.many-teacher {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .modal--teacher {
                    width: 49%;
                    @include mq(sp) {
                        width: 100%;
                    }
                }
            }
            &-text01 {
                font-size: 14px;
                margin-bottom: 7px;
                font-weight: bold;
                @include mq(sp) {
                    font-size: 1.8rem;
                    margin-bottom: 0;
                }
            }
            &-text02 {
                font-size: 11px;
                font-weight: 400;
                @include mq(sp) {
                    font-size: 1.5rem;
                }
            }
        }
        &--teacher {
            display: flex;
            align-items: center;
            @include mq(pc) {
                &:not(:nth-child(1)):not(:nth-child(2)) {
                    margin-top: 20px;
                }
            }
            @include mq(sp) {
                &:not(:nth-child(1)) {
                    margin-top: 2.4rem;
                }
            }
            &-avatar {
                width: 64px;
                min-width: 64px;
                height: 64px;
                margin-right: 15px;
                @include mq(sp) {
                    width: 8.0rem;
                    height: 8.0rem;
                    margin-right: 2.5rem;
                    min-width: 8.0rem;
                }
            }
        }
        &--close {
            background-color: $colortxtGray;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            bottom: -104px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            @include mq(sp) {
                width: 8rem;
                height: 8rem;
                bottom: -11rem;
            }
            &:hover {
                opacity: 0.85;
            }
            &-x {
                width: 40px;
                height: 40px;
                margin:  0 auto;
                position: relative;
                top: 50%;
                @include mq(sp) {
                    width: 4rem;
                    height: 4rem;
                }
                div {
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    @include mq(sp) {
                        height: .1rem;
                    }
                    &:nth-child(1) {
                        transform: rotateZ(45deg);
                    }
                    &:nth-child(2) {
                        transform: rotateZ(-45deg);
                    }
                }
            }
        }
    }
}
.c-seminar-table {
    &.layout-112 {
        .sem-table__item {
            @include mq(pc) {
                &:nth-child(1) {
                    width: 25%;
                }
                &:nth-child(2) {
                    width: 25%;
                }
                &:nth-child(3) {
                    width: 50%;
                    .sem02-table__content {
                        width: 50%;
                    }
                }
            }
            @include mq(sp) {
                &>._flex {
                    flex-direction: column;
                }
                ._flex .sem02-table__content:nth-child(2) {
                    margin-top: 0;
                }
            }
        }
    }
    &.layout-22 {
        .sem-table__item {
            @include mq(pc) {
                &:nth-child(1) {
                    width: 50%;
                }
                &:nth-child(2) {
                    width: 50%;
                }
                &>._flex {
                    .sem02-table__content {
                        width: 50%;
                    }
                }
            }
            @include mq(sp) {
                &>._flex {
                    flex-direction: column;
                }
                ._flex .sem02-table__content:nth-child(2) {
                    margin-top: 0;
                }
            }
        }
    }
    &.layout-1 {
        .sem-table__item {
            @include mq(pc) {
                width: 100%;
                &>._flex {
                    .sem02-table__content {
                        width: 25%;
                    }
                }
            }
            @include mq(sp) {
                &>._flex {
                    flex-direction: column;
                }
                ._flex .sem02-table__content:nth-child(2) {
                    margin-top: 0;
                }
            }
        }
    }
}
.sem-table__title.sem-table__title-sem02 {
    font-size: 18px;
    font-weight: bold;
    @include mq(sp) {
        font-size: 2rem;
    }
}
.c-sem-button-fullwidth {
    text-align: center;
    background-color: $grayPrimary;
    padding: 40px;
    margin-bottom: -80px;
    @include mq(sp) {
        padding: 0;
        padding-top: 4.6rem;
        padding-bottom: 4.8rem;
        margin-bottom: 0;
        margin-top: -7rem;
        margin-bottom: -4rem;
    }
    .v-btn {
        width: 600px;
        height: 60px;
        margin: 0 auto;
        border-radius: 30px;
        position: relative;
        @include mq(sp) {
            width: 60rem;
            border-radius: 4rem;
            height: 8rem;
        }
        &:hover {
            opacity: 0.85;
        }
        &--text {
            margin-top: 15px;
            font-size: 13px;
            @include mq(sp) {
                margin-top: 2.3rem;
                font-size: 1.5rem;
            }
        }
        &--group {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            @include mq(sp) {
                width: 2.4rem;
                height: 2.4rem;
            }
            @include mq(sp) {
                right: 3.2rem;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        &--btn {
            font-size: 15px;
            @include mq(sp) {
                font-size: 2rem;
            }
        }
    }
}
.en .sem .v-btn>div:nth-child(1) {
    text-align: center;
    @include mq(sp) {
        font-size: 1.8rem;
    }
}
.seminar-modal-open {
    overflow-y: hidden;
    // z-index: 1;
    height: 100%;
    @at-root {
        html .seminar-modal-open {
            height: 100%;
            overflow-y: hidden;
        }
    }
}