.v-reg {
    .v-reg{
        &--lineh2{
            line-height:2;
            @include mq(sp){
                line-height: 1.75;
            }
        }
        &--margin{
            margin-top: 0.8rem;
            margin-bottom: 8.2rem;
            @include mq(sp){
                margin-top: 3rem;
                margin-bottom: 8rem;
            }
        }
        &--border-bottom{
            border-bottom: 1px solid #cccccc;
        }
        &--mt-sp{
            @include mq(sp) {
                margin-top: 7.8rem;
            }
        }
        &--margin-01{
            margin-top: 15px;
            margin-bottom: 39px;
        }
        &--margin-02{
            margin-top: 31px;
            margin-bottom: 45px;
            font-size: 1.7rem;
            @include mq(sp) {
                font-size: 2rem;
                margin-top: 3.9rem;
                margin-bottom: 4.6rem;
            }
        }
        &--box-contact{
            padding-top: 32px;
            padding-bottom: 22px;
            font-size: 1.8rem;
            margin-bottom: 120px;
            line-height: 1.9;
            @include mq(sp) {
                padding-top: 3.8rem;
                padding-bottom: 3.9rem;
                margin-bottom: 8rem;
            }
        }
        &-picture{
            margin-top: 43px;
            margin-bottom: 99px;
        }
        &--font-pc20{
            font-size: 2rem;
        }
        &--font15{
            font-size: 1.5rem;
        }
        &--font15-sp18{
            font-size: 1.5rem;
            @include mq(sp){
                font-size: 1.8rem;
            }
        }
        &--cus-mt{
            margin-top: -1rem;
        }
        &-cus-letter-space{
            letter-spacing:0.2px;
        }
        &-cus-line-height {
            line-height:2;
        }
        
    }
    .v-reg-discription{
        &__text{
            margin-top: 25px;
            font-size: 1.7rem;
            @include mq(sp){
                margin-top: 4rem;
                font-size:2rem;
            }
        }
        &__text1{
            margin-top: 23px;
            margin-bottom: 80px;
            @include mq(sp){
                margin-top: 2.4rem;
                margin-bottom: 7.9rem;
                font-size:1.7rem;
            }
            font-size: 1.4rem;
            text-align: center;
        }
    }
    .v-reg-link{
        &__item{
            margin-top: 50px;
            margin-bottom: 92px;
            @include mq(sp){
                margin-top: 6.3rem;
                margin-bottom: 10rem;
            }
            &:nth-child(1){
                margin-right: 29px;
            }
        }
    }
    .v-reg-title{
        margin-bottom: 31px;
        border-bottom: 2px solid #222222;
        font-size: 2.7rem;
        padding-bottom: 16px;
        @include mq(sp){
            margin-bottom: 4rem;
            padding-bottom: 1.7rem;
            font-size: 3.2rem;
            border-bottom: 1px solid #222222;
        }
        picture{
            margin-right: 14px;
            @include mq(sp) {
                width: 4rem;
                display : block;
                margin-right: 1.8rem;
                line-height: 1;
            }
        }
    }
    .v-reg-list{
        padding-bottom: 28px;
        @include mq(sp) {
            padding-bottom: 2rem;
        }
        &__text-pd{
            padding-bottom: 2.5rem;
            @include mq(sp) {
                font-size:2rem !important;
            }
        }
        &__item{
            padding-bottom: 33px;
            padding-top: 32px;
            align-items: center;
            font-size: 1.8rem;
            @include mq(sp) {
                padding-bottom: 4rem;
                padding-top: 2.6rem;
                align-items: self-end;
            }
            >p{
                &:nth-child(1){
                    padding: 1.4rem 1.8rem 1.5rem 1.8rem;
                    letter-spacing: 1.08px;
                    line-height: 1;
                    margin-right: 16px;
                    @include mq(sp) {
                        margin-right: 2.4rem;
                        border: 1px #1266af solid;
                    }
                }
                &:nth-child(2){
                    font-size: 1.5rem;
                    @include mq(sp) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
        &__item:not(:last-child){
            border-bottom: 1px solid $pre_gray;
        }
        &__text{
            font-size: 1.7rem;
        }
    }
    .v-reg-margin-b{
        margin-bottom: 120px;
        @include mq(sp) {
            margin-bottom: 8rem;
        }
    }
    .v-flex-al-center{
        align-items: center !important;
    }
}

