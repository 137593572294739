
.c-step {
  display: flex;
  justify-content: center;
  &__txt,&__box {
    display: block;
    text-align: center;
  }
  &__txt {
    color: $entryGray;
    @include mq(sp) {
      margin-bottom: 5px;
    }
  }
  &__box {
    position: relative;
    background: $entryGray;
    color: #ffffff;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      position: absolute;
      left: calc(100% + 30px);
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 24px;
      fill: $entryGray;
      @include mq(sp) {
        display: none;
      }
    }
  }  
  &__item {
    @include mq(pc) {
      @include fb_width(180px);
      margin: 0 36px;
      font-size: 1.7rem;
    }
    @include mq(sp) {
      flex-basis: 140 / $spDesign + vw;
      margin: 0 2vw;
    }
    &.active {
      .c-step__txt {
        color: $colorPrimary;
      }
      .c-step__box {
        background: $colorPrimary;
        svg {
          fill: $colorPrimary;
        }
      }
    }
  }
  &__note {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
    @include mq(sp) {
      font-size: 1.6rem;
    }
  }
}

.c-entry-ttl {
  &--primary {
    font-size: 2.7rem;
    padding-bottom: 9.6px;
    margin-bottom: 32px;
    border-bottom: 2px solid $colorExtra;
    @include mq(sp) {
      margin-bottom: 5.6vw;
      font-size: 4.5rem;
      text-indent: -1em;
      padding-left: 1em;
    }
  }
}

.entry-confirm {
  .c-form-mod__ttl {
    padding: 15px 0;
  }
  .c-form-mod__input {
    font-weight: lighter;
  }
}

.p-entry {
  &-result {
    .p-total-koma {
      width: 237px;
      height: 70px;
      background: $requireBg;
      font-size: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      @include mq(sp) {
        font-size: 6.6rem;
      }
    }
    &__box {
      @include mq(pc) {
        margin: 0 24px;
      }
      @include mq(sp) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }
    }
  }
  &-layout {
    &-list {
      margin-right: 3.04%;
      @include mq(pc) {
        font-size: 1.3rem;
        @include fb_width(144 / 820 *100%);
      }
      @include mq(sp) {
        flex-basis: 48%;
        margin-right: 0;
      }
      &__inner {
        border: 1px solid #eeeeee;
        label {
          position: relative;
          padding: 30px 8px 16px;
          display: block;
          text-align: center;
          &:before {
            position: absolute;
            left: 3px;
            top: 3px;
            content: "";
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: $placeholder;
            @include mq(sp) {
              left: 15px;
              top: 20px;
            }
          }
        }
        @at-root {
          .p-guide {
            .p-entry-layout-list__inner {
              label {
                &:before {
                  content: none;
                }
              }

            }
          }
        }
        img {
          @include mq(pc) {
            margin-top: 10px;
          }
        }
      }
      &__catch {
        margin-top: 10px;
        color: $require;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:nth-child(n+3) {
        @include mq(sp) {
          margin-top: 20px;
        }
      }
    }
    &__radio {
      display: none;
      &:checked {
        & + label {
          &:before {
            background: $require;
          }
        }
      }
    }
  }
  .p-entry-send {
    @include mq(pc) {
      order: 2;
      margin-left: 40px;
    }
  }
}