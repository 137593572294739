$entryGray:#d4d4d4;
$inputBg:#f5f5f5;
$placeholder:#cccccc;
$require:#d40000;
$requireBg:rgba(#d40000,.1);
@mixin not-active {
  background:#fff!important;
  border: 1px solid $entryGray !important;
  color: $entryGray !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.c-form-red {
  color: $require;
}

.c-mark {
  line-height: 1;
  font-size: 15 / 17 + em;
  padding: 3px 14px;
  display: inline-block;
  background: $inputBg;
  color: #999999;
  margin-right: 24px;
  border-radius: 6px;
  @include mq(sp) {
    margin-right: 12px;
  }
  &--required {
    @extend .c-mark;
    background: $require;
    color: #ffffff;
    &-text {
      color: $require;
    }
  }
}

.js-error {
  &-line {
    .c-form-mod__ttl {
      @include mq(pc) {
        padding-top: 56px;
      }
    }
    input,select,textarea {
      border: 1px solid $require !important;
      background: $requireBg !important;
    }
    input[type="radio"] + .c-radio-label .c-radio-txt {
      background: $requireBg !important;
      padding: 5px;
    }
    .p-entry-layout-list__inner {
      background: $requireBg !important;
      border: 1px solid $require !important;
    }
    input[type="checkbox"] + label .c-input-check-txt {
      padding: 5px;
      background: $requireBg;
    }
  }
  &-note {
    color: $require;
    margin-bottom: 5px;
    font-size: 14 / 17 + em;
  }
  &-box {
    max-width: 900px;
    margin: 0 auto 50px;
    background: $require;
    color: #ffffff;
    border-radius: 6px;
    dt {
      padding: 20px 10px 10px 10px;
    }
    dd {
      padding: 10px 10px 20px 10px;
    }
  }
}

input:not([type="radio"]),textarea {
  border: none;
  border-radius: 0;
  outline: none;
  @include font_ja;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.c-input-check {
  display: none;
  & + label {
    position: relative;
    display: flex;
    &:before {
      content:"";
      display: block;
      width: 32px;
      height: 32px;
      background: $inputBg;
    }
  }
  &:checked {
    & + label {
      &:after {
        content:"";
        background: url('../img/entry/ico_check.png') no-repeat top center;
        position: absolute;
        left: 7px;
        top: 9px;
        width: 19px;
        height: 14px;
      }
    }
  }
  &-txt {
    margin-left: 15px;
  }
  &-column {
    li {
      &:nth-child(n+2) {
        margin-top: 16px;
      }
    }
  }
}

.c-radio {
  display: none;
  & + .c-radio-label {
    position: relative;
    line-height: 1;
    &:before {
      content: "";
      display: block;
      width: 32px;
      height: 32px;
      border: 1px solid $placeholder;
      border-radius: 50%;
    }
  }
  &:checked {
    & + .c-radio-label {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: 11px;
        width: 12px;
        height: 12px;
        background: $colorPrimary;
        border-radius: 50%;
      }
    }
  }
  &-column {
    li {
      &:nth-child(n+2) {
        margin-top: 23px;
      }
    }
  }
  &-label {
    display: flex;
    align-items: center;
  }
  &-txt {
    margin-left: 15px;
  }
}

::placeholder {
  color: $placeholder;
  font-weight: bold;
  letter-spacing: $baseTracking;
  @include mq(pc) {
    font-size: 1.7rem;
    @at-root {
      @include mq(pc) {
        input {
          font-size: 1.7rem;
        }
        textarea {
          font-size: 1.7rem;
        }
      }
    }
  }
  @include mq(sp) {
    font-size: 2.6rem;
    line-height: 1;
  }
}

.c-input-box {
  background: $inputBg;
  width: 100%;
  height: 60px;
  padding: 3px 20px 3px 20px;
  @include mq(sp) {
    padding: 5px;
  }
  &--sm {
    @extend .c-input-box;
    width: 110px;
    @include mq(sp) {
      width: 20vw;
    }
    &-x {
      @extend .c-input-box;
      width: 72px;
    }
  }
  &--textarea {
    @extend .c-input-box;
    height: 240px;
    @include mq(pc) {
      padding: 10px 20px 10px 20px;
    }
  }
}

.c-select-box {
  @extend .c-input-box;
  max-width: 448px;
  background: url('../img/entry/ico_sankaku_01.png') no-repeat right 20px top 23px;
  font-size: 1.7rem;
  font-weight: bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  @at-root {
    .js-error-line {
      .c-select-box {
        background: url('../img/entry/ico_sankaku_01.png') no-repeat right 20px top 23px rgba(212, 0, 0, .1) !important;
      }
    }
  }
  @include mq(sp) {
    font-size: 2.6rem;
  }
}

.c-search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $colorPrimary;
  color: $colorPrimary;
  width: 110px;
  height: 48px;
  font-size: 18 / 17 + em;
  &:hover {
    cursor: pointer;
  }
  @include mq(sp) {
    margin-top: 10px;
  }
}

.c-form {
  &-mod {
    width: 100%;
    @include mq(pc) {
      font-size: 1.7rem;
    }
    &__ttl {
      text-align: left;
      @include mq(pc) {
        width: 260px;
        vertical-align: top;
        padding-top: 32px;
      }
      @include mq(sp) {
        padding: 15px 0;
        display: block;
      }
      &--empty {
        @include mq(pc) {
          width: 260px;
        }
      }
    }
    &__input {
      padding: 15px 0;
      @include mq(pc) {
      }
      @include mq(sp) {
        display: block;
        padding-top: 0;
      }
    }
  }
  &-comment {
    margin-top: 10px;
    font-size: 13 / 17 + em;
    li {
      text-indent: -1em;
      padding-left: 1em;
    }
  }
  &-hyphen {
    position: relative;
    padding: 0 31px;
    display: inline-block;
    @include mq(sp) {
      padding: 0 5vw;
    }
    &:not(:last-of-type) {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        background: $colorParagraph;
        transform: translateY(-50%);
        right: -16px;
        width: 30px;
        height: 2px;
        z-index: 100;
        @include mq(sp) {
          width: 3vw;
          right: -1.5vw;
        }
      }
    }
  }
  &-sec {
    &:nth-of-type(n+2) {
      margin-top: 80px;
      @include mq(sp) {
        margin-top: 40px;
      }
    }
  }
  &-btn-area {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    @include mq(sp) {
      flex-wrap: wrap;
    }
  }
}

//not-active
.c-confirm-btn {
  &.is-disabled {
    @include not-active;
    &:hover {
      cursor: default;
    }
  }
}

.p-waribki {
  th,td {
    @include mq(sp) {
      display: block;
    }
  }
  &-cate {
    padding: 10px 0;
    display: block;
    text-align: center;
    border-radius: 6px;
    @include mq(pc) {
      width: 211px;
    }
    &--mainly {
      @extend .p-waribki-cate;
      border: 1px solid $require;
      color: $require;
    }
    &--sub {
      @extend .p-waribki-cate;
      color: $colorParagraph;
      border: 1px solid $colorParagraph;
    }
  }
  &__txt,&__ttl {
    vertical-align: middle;
    padding: 8px 0;
  }
  &__txt {
    @include mq(pc) {
      font-size: 1.3rem;
    }
  }
}
