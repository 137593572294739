.c-lower {
  &-hero {
    padding-top: 280px;
    background: url(../img/bg_lower_kv_pc.jpg) no-repeat top center / cover;
    height: 548px;
    @include mq(sp) {
      padding-top: 77.3vw;
      padding-bottom: Rem(51px);
      // height: 106vw;
      height: auto;
      background: url(../img/bg_lower_kv_sp.jpg) no-repeat top center / cover;

      background-position-y: -2.7rem;
      background-position-x: -18.1rem;
      background-size: 125%;
    }
    &__inner {
      padding: 20px 20px 20px 120px;
      background: $colorPrimary;
      display: inline-block;
      color: #ffffff;
      font-size: 4.8rem;
      @include shadowSm;
      @include mq(pc) {
        min-width: 690px;
        @at-root {
          .en {
            .c-lower-hero__inner {
              @include mq(pc) {
                padding: 20px 44px 20px 120px;
              }
            }
          }
        }
      }
      @include mq(sp) {
        width: 60rem;
        padding: 4vw 1vw 4vw 5.3vw;
      }
    }
    &__heading {
      letter-spacing: 1.6px;
      font-size:4.8rem;
      @include mq(sp) {
        line-height: 1.3;
        font-size: Rem(36px);

      }
    }
  }
  &-wrap {
    &__container {
      max-width: 1320px;
      margin-top: -30px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 20px;
      @include mq(sp) {
        padding: 0 2.7vw;
      }
      &-inner {
        background: rgba(#ffffff,.9);
        border-radius: 15px;
        padding: 90px 7.8%;
        // @include shadowSm;
        box-shadow: 0 0 16px 12px rgba(97, 97, 97, 0.03);
        @include mq(sp) {
          margin-top: -10.6vw;
          padding: 8vw 4vw;
        }
      }
    }
  }
  &-sec {
    margin-top: 110px;
    @include mq(sp) {
      margin-top: 13.3vw;
    }
    &--sm {
      &:nth-child(n+2) {
        margin-top: 80px;
        @include mq(sp) {
          margin-top: 10vw;
        }        
      }

    }
  }
}

.c-breadcrumb {
  font-size: 1.7rem;
  font-weight: 400;
  @include mq(pc) {
    display: flex;
  }
  li {
    &:nth-child(n+2) {
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
      &:before {
        position: absolute;
        content: '/';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .c-breadcrumb__link {
        padding-left: 10px;
      }
    }
  }
  &__link {
    padding-bottom: 3px;
    display: inline-block;
    color: #ffffff;
  }
}