.p-about {
  &__table {
    .c-table-line {
      &:first-of-type {
        padding-top: 0;
      }
      @include mq(pc) {
        dt {
          flex-basis: 280px;
        }
        dd {
          flex-basis: calc(100% - 280px);
        }
      }      
    }
  }

}

.test {
  display: grid;
  display: -ms-grid;
  max-width: 960px;
  margin: 0 auto;
  grid-template-columns:1fr 124px 252px 290px 180px;
  -ms-grid-columns:1fr 124px 252px 290px 180px;
}