.p-list-exhibitor-search {
  @include mq(pc) {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-list-exhibitor-search__list {
  margin-top: 10px;
  display: flex;
  margin-right: 2.6%;
  & > li {
    padding-top: 6px;
    padding-bottom: 6px;
    &:first-child {
      padding: 6px 9px;
      background: #f4f4f5;
    }
  }
}

.p-list-exhibitor-search__item {
  margin-right: 13px;
  padding-top: 6px;
  &:hover {
    .p-list-exhibitor-search__link {
      text-decoration: none;
    }
  }
}

.en {
  .p-list-exhibitor-search__item {
    padding: 6px 9px;
    background: #f4f4f5;
  }
}

a {
  &.p-list-exhibitor-search__link {
    text-decoration: underline;
  }
}

.p-list-exhibitor-search__link {
  display: block;

}

.p-list-exhibitor-search-others {
  margin-top: 20px;
  display: flex;
  flex: 0 1 100%;
}

.p-list-exhibitor-search-others__link {
  margin-right: 20px;
  padding: 8px 10px;
  display: inline-block;
  color: #ffffff;
  transition:$transition;
  &.-color-orange {
    background: #ff8e10;
  }
  &.-color-blue {
    background: #009ddf;
  }
  &:hover {
    opacity: $opacity;
  }
}

.p-list-exhibitor-list {
  margin-top: 60px;
  @include mq(sp) {
    border-bottom: 1px #777 solid;
  }
  .c-accordion-box__content {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
  }
}

.p-list-exhibitor-list-block {
  & + & {
    @include mq(pc) {
      margin-top: 60px;
    }
  }
}

.p-list-exhibitor-list__heading {
  font-size: 2.0rem;
  font-weight: bold;
  line-height: 1.786;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(pc) {
    margin-bottom: 15px;
  }
  @include mq(sp) {
    background: #ff668c;
    position: relative;
    padding: 4.4vw;
    border: 1px #777 solid;
    border-bottom: 0;
    color: #fff;
    font-size: 2.8rem;
    .c-accordion-box__ico {
      transform: rotate(-45deg);
      position: relative;
      top: 3px;
      border-color: #fff;
    }
  }
  @include mq(sp) {
    &[aria-expanded="false"] {
      & + .c-accordion-box__content {
        display: none;
      }
    }
    &[aria-expanded="true"] {
      background: $colorPrimary;
      color: #fff;
      .c-accordion-box__ico {
        transform: rotate(135deg);
      }
    }
  }
}

.p-list-exhibitor-list-mod {
  @include mq(pc) {
    border-bottom: 1px #777 solid;
  }
}

.p-list-exhibitor-list__sub-ttl {
  border: 1px #777 solid;
  border-bottom: none;
  padding: 12px 0 12px 17px;
  width: 100%;
  background: #ff668c;
  color: #ffffff;
  font-size: 2rem;
  @include mq(sp) {
    border-bottom: 0;
    padding: 3.64vw 0 3.64vw 5.5vw;
    font-size: 2.4rem;
  }
}

.p-list-exhibitor-list-box {
  border-left: 1px #777 solid;
  border-right: 1px #777 solid;
  @include mq(pc) {
    border-top: 1px #777 solid;
  }
  table {
    width: 100%;
  }
  tr {
    & + tr {
      @include mq(pc) {
      border-top: 1px #777 solid;
      }
    }
  }
}

.p-list-exhibitor-list-box__items {
  width: 50%;
  @include mq(sp) {
    display: block;
    border-top: 1px #777 solid;
    width: 100%;
  }
  a {
    position: relative;
    padding: 14px 100px 14px 17px;
    display: block;
    transition:$transition;
    color: $blueRight;
    @include mq(sp) {
      padding: 14px 85px 14px 17px;
    }
    &:hover {
      opacity: $opacity;
    }
  }
  &:nth-child(odd) {
    @include mq(pc) {
      border-right: 1px solid #777;
    }
  }
}

.p-list-exhibitor-list-number {
  position: absolute;
  text-align: center;
  display: inline-block;
  background: #02416e;
  color: #fff;
  width: 80px;
  top: 14px;
  right: 15px;
  @include mq(sp) {
    width: 20%;
    top: 3.85vw;
  }
}

.p-list-exhibitor-list__heading-ico {
  img {
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.list-about {
  position: relative;
  padding: 25px 20px;
  border: 1px #ccc solid;
  @include mq(sp) {
    display: grid;
    padding: 20px;
    // grid-template: repeat(1,100%);
  }
  & + .list-about {
    margin-top:30px;
  }
}

.list-about-name {
  padding-bottom: 30px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}

.list-about__ttl {
  padding-bottom: 0;
  margin-left: 0;
  font-size: 2.5rem;
  line-height: 1.4;
  color: $blueRight;
  border-bottom: 0;
  font-weight: bold;
  &:after {
    content: none;
  }
}

.list-about-block:nth-child(n+2) {
  @include mq(pc) {
    margin-top: 20px;
  }
  margin-top: 10px;
}

.list-about .border-bottom {
  border-bottom: 1px #ddd solid;
}

.list-about__txt-box {
  // float: left;
  @include mq(pc) {
    width: 82.9%;
  }
}

.list-about__logo-box {
  // float: right;
  @include mq(pc) {
    position: absolute;
    top: 25px;
    right: 20px;
    // max-width: 128px;
  }
  @include mq(sp) {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
    margin-bottom: 20px;
  }
}

.list-about-detail {
  padding-bottom: 20px;
  color: #333;
}

.list-about-detail__items {
  @include mq(sp) {
    margin-top: 20px;
  }
  dt, dd {
    @include mq(pc) {
      display: inline-block;
    }
  }
  dt:nth-child(n+2) {
    @include mq(pc) {
      margin-left: 80px;
    }
    @include mq(sp) {
      margin-top: 10px;
    }
  }
}

.list-about-category-box {
  font-size: 0;
  @include mq(pc) {
    float: left;
  }
  @include mq(sp) {
    margin-top: 10px;
  }
  &:first-child {
    margin-right: 10px;
  }
  li {
    display: inline-block;
  }
  &-lang {
    @include mq(pc) {
      padding: 0 10px;
      border-left: 1px #aaa solid;
    }
  }
  &-link {
    @include mq(pc) {
      // float: right;
    }
  }
  .btn-homepage {
    @include mq(pc) {
      width: 124px;
      height: 31px;
      transition: $transition;
      &:hover {
        opacity: $opacity;
      }
    }
    @include mq(sp) {
      img {
        height: 30px;
      }
    }
  }
  @at-root {
    .en {
      .list-about-category-box {
        .btn-homepage {
          @include mq(pc) {
            width: 103px;
          }
      }
      .btn-appoint {
        @include mq(pc) {
          width: 137px;
        }
      }
      }
    }
  }
  .btn-appoint {
    @include mq(pc) {
      width: 239px;
      height: 31px;
      transition: $transition;
      &:hover {
        opacity: $opacity;
      }
    }
    @include mq(sp) {
      img {
        height: 30px;
      }
    }
  }
}

.list-about-category-box-lang {
  @include mq(pc) {
    text-align: center;
  }
  li {
    padding: 5px 10px;
    font-size: 1.3rem;
    text-transform: uppercase;
    &:nth-child(n+2) {
      margin-left: 10px;
    }
  }
}

.list-about-category-box-link li:nth-child(n+2) {
  margin-left: 10px;
  @include mq(sp) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.list-about-category-ico li {
  width: 25px;
  & + li {
    margin-left: 5px;
  }
}

.list-about-lang--en {
  border: 1px solid $blueRight;
  color: $blueRight;
}

.list-about-lang--ch {
  border: 1px solid $redRight;
  color: $redRight;
}


