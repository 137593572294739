.visit {
    .c-visit-wrap{
        &__container{
            max-width: 1080px;
            margin: 0 auto;
            margin-top: 80px;
            @include mq(sp){
                margin-left: 3.7rem;
                margin-right: 3.7rem;
                margin-top: Rem(58px);
            }
        }
    }
    .visit-box{
        padding-top: 37px;
        padding-bottom: 36px;
        font-size: 1.8rem;
        text-align: center;
      
        @include mq(sp){
            font-size: Rem(24px);
            padding-top: Rem(31px);
            padding-bottom: Rem(30px);
         
        }
        &__child{
            padding-top: 39px;
            padding-bottom: 39px;
            margin-bottom: 40px;
            @include mq(sp){
                padding-top: Rem(39px);
                padding-bottom: Rem(42px);
                padding-left: Rem(27px);
                padding-right: Rem(27px);
                margin-bottom: Rem(40px);
                letter-spacing:1.75;
            }
        }
        &__title{
            padding-bottom: 21px;
            font-size: 1.8rem;
            letter-spacing: 0.72px;
            @include mq(sp){
                padding-bottom: Rem(21px);
            }
        }
        &__text{
            font-size: 1.3rem;
            @include mq(sp){
                font-size: Rem(15px);
                letter-spacing: 0.1px;

            }
        }
    }

    .v-list{
        &__item{
            >p{
                &:nth-child(1){
                    padding: 14px 0px 15px 17px;
                    font-size: 1.8rem;
                    line-height:1.1;
                    @include mq(sp){
                        font-size: Rem(24px);
                        padding: Rem(22px) 0px Rem(24px) Rem(17px);
                        line-height: 1.75;
                    }
                }
                &:nth-child(2){
                    padding: 23px 0px 40px 6px;
                    font-size: 1.5rem;
                    @include mq(sp){
                        font-size: 1.8rem;
                        padding: Rem(23px) 0px Rem(41px) Rem(4px);
                    }
                }
            }
        }
        &__item-custom-cn{
            padding-top: 32px;
            @include mq(sp) {
                padding-top: 4rem;
            }
            a{
                padding-top: 32px;
                padding-bottom:75px;
                display: inline-block;
                padding-left: 4px;
                font-size: 1.7rem;
                @include mq(sp){
                    font-size: 2rem;
                    padding-top: Rem(31px);
                    padding-bottom:Rem(73px);
                }
            }
        }
        &__item-detail{
            >p{
                &:nth-child(1){
                    padding: 14px 0px 15px 17px;
                    font-size: 1.8rem;
                    line-height: 1.1;
                    @include mq(sp){
                        font-size: Rem(24px);
                    }
                }
                &:nth-child(2){
                    padding: 23px 0px 0px 6px;
                    @include mq(sp){
                        font-size: Rem(18px);
                        padding: Rem(23px) 0px 0px Rem(4px);
                    }
                }
                &:nth-child(3){
                    font-size: 1.3rem;
                    padding-top: 17px;
                    padding-bottom: 40px;
                    @include mq(sp){
                        font-size: Rem(17px);
                        padding-top: Rem(23px);
                        padding-bottom: Rem(39px);
                    }
                }
            }

        }
        &__text{
            padding-top: 22px;
            font-size: 1.5rem; 
            @include mq(sp){
              font-size: Rem(18px);
              padding-top: Rem(23px);
            }   
        }
        &__margin{
            margin-top: 60px;
            margin-bottom: 82px;
            @include mq(sp){
                margin-top: Rem(48px);
                margin-bottom: Rem(45px);
            }
        }
    }
    .v-not{
        padding-bottom: 119px;
        @include mq(sp){
            padding-bottom: Rem(80px);
        }
        &__content-custom-cn{
            margin-bottom: 121px;
            @include mq(sp){
                margin-bottom: Rem(82px);
            }
        }
        &__item:nth-child(1){
            padding-top: Rem(40px);
            padding-bottom: Rem(32px);
            align-items: center;

        }
        &__item:nth-child(2){
            padding-bottom: 1.9rem;
        }
        &__picture{
            padding-right: 32px;
            align-items: center;
            @include mq(sp){
                padding-right: Rem(25px);
                width: Rem(90px);
                min-width: Rem(90px);
            }

        }
        &__text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include mq(sp){
                display:block;
            }
            >p{
                &:nth-child(1){
                    font-size: 1.8rem;
                    line-height: 1.33;
                    letter-spacing: 0.72px;
                    @include mq(sp){
                        font-size: Rem(20px);
                    }
                }
                &:nth-child(2){
                    font-size: 1.5rem;
                    @include mq(sp){
                        font-size: Rem(17px);
                    }
                }
            }
            &--fd{
                padding-top:17px;
                font-size: 1.3rem;
                @include mq(sp){
                    padding-top: 2.9rem;
                    font-size: Rem(17px);
                }
            }
        }
    }
    .v-title{
        padding-top: 78px;
        padding-bottom: 24px;
        padding-left: 4px;
        font-size: 2.7rem;
        letter-spacing: 1.08px;
        border-bottom: 2px solid $colorExtra;

        @include mq(sp){
            font-size: Rem(32px);
            padding-top: Rem(57px);
            padding-bottom: Rem(21px);
            border-bottom: 1px solid $colorExtra;
        }
    }
    .v-discription{
        padding-top: 31px;
        padding-bottom: 58px;
        font-size: 1.7rem;
        font-weight: 500;
        @include mq(sp){
            padding-top: Rem(39px);
            padding-bottom: Rem(48px);
            font-size: Rem(20px);
        }
        &__text{
            padding-top: 19px;
            font-size: 1.4rem;
            line-height: 2.14;
            @include mq(sp){
                font-size: Rem(17px);
            }
        }
    }
    .visit-bg-primary {
        position:relative;
        background-color: #1266af;
        >p{
            position: relative;
            z-index: 1;
        }
    }
    .visit-bg-primary::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: url(../img/press/bg-title.png) no-repeat;
        background-size: 100% 100%;
        top: 0;
        right: 0;
        z-index: 0;
    }
    .visit-bg-secondary{
        background-color: $visit_BgSecondary;
    }
    .v-lineHeight{
        line-height:1.75;
    }
    .v-font{
        @include mq(sp){
            font-size: Rem(17px);
        }

    }
    .v-btn{
        width: Rem(600px);
        height: 60px;
        border-radius: 30px;
        border: solid 1px #f92157;
        font-size: 1.7rem;
        margin: auto;
        padding-left: 30px;
        
        @include mq(sp){
            height: Rem(80px);
            font-size: Rem(22px);
        }
        &--padding{
            padding-top: 40px;
            padding-bottom: 40px;
      
            @include mq(sp){
                padding-top: Rem(48px);
                padding-bottom: Rem(48px);
                margin-left: -3.8rem;
                margin-right: -3.8rem;
            }
        }
        a{
            width: 100%;
            text-align: center;

        }
        &--group{
            display:flex;
            justify-content:center;
            align-items:center;
            margin-right: 24px;
            picture{
                display: inline-flex;
                @include mq(sp){
                    display:contents;
                }
                >img{
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    @include mq(sp) {
                        width: 2.4rem;
                        min-width: 2.4rem;
                        height: 2.4rem;
                    }
                }
            }
        }
    }
    .v-picture{
        padding-top: 40px;
        padding-bottom: Rem(80px);
        @include mq(sp){
            justify-content:space-between;
            padding-right: Rem(77px);
            padding-left: Rem(77px);
            padding-top: Rem(37px);
        }
        li{
            width: Rem(100px);
            min-width: Rem(100px);
        }
    }
    .v-width-94{
        width: 6.2rem;
        min-width: 6.2rem;
        margin-right: 32px;
        min-width: 6.2rem;
        height: 6.2rem;
        @include mq(sp) {
            margin-right: 2.5rem;
        }
    }
    .v-dowload{
        width: Rem(26px);
        height: Rem(23px);
        object-fit: contain;
    }
    .v-mt-32{
        margin-top: 32px;
        @include mq(sp){
            margin-top: Rem(24px);
        }
        &__sp40{
            margin-top: 32px;
            @include mq(sp){
                margin-top: Rem(40px);
            }
        }
    }
    .v-note{
        margin-bottom: 62px;
        margin-top: 17px;
        padding-left: 4px;
        &--font{
            font-size:1.3rem;
            @include mq(sp){
                font-size: 1.7rem;
    
            }
        }
        @include mq(sp){
            font-size: Rem(17px);
            margin-bottom: Rem(40px);
            margin-top: Rem(25px);

        }
    }


}