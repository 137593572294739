// 大まかなレイアウトのみ
html {
  &.is-modal {
    overflow: hidden;
  }
}

.is-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(#000,.7);
  z-index: 1000;
  backface-visibility: hidden;
}
.c-modal {
  &-wrap {
    display: none;
    width: 100%;
    height: 100%;
  }
  &-container {
    display: table-cell;
    vertical-align: middle;
    &__inner {
      background: #ffffff;
      max-width: 700px;
      margin: 0 auto;
    }
  }
}