.p-others {
  &-list {
    font-size: 1.4rem;
    @include mq(pc) {
      display: flex;
      justify-content: space-between;
    }
    li {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex-basis: calc(100% / 3);
      text-align: center;
      &:nth-child(n+2) {
        @include mq(pc) {
          border-left: 1px solid $colortxtGray;
        }
      }
      figcaption {
        margin-top: 30px;
      }
    }
  }
}