
//basic accrodion
.l-accrodion {
  &-items {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    transition: 1s;
    background: #dddddd;
  }
  &-trigger {
    &[aria-expanded="true"] {
      & + .l-accrodion-items {
        height: auto;
        opacity: 1;
      }
    }
  }
}

//basic accrodion
.l-accordion {
  &-items {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    transition: 1s;
    background: #dddddd;
  }
  &-trigger {
    &[aria-expanded="true"] {
      & + .l-accordion-items {
        height: auto;
        opacity: 1;
      }
    }
  }
}

.c-accordion {
  &-box {
    padding: 20px;
    border: 1px solid $gray;
    @include mq(sp) {
      padding: 10px;
    }
    &:nth-child(n+2) {
      margin-top: 40px;
      @include mq(sp) {
        margin-top: 20px;
      }
    }
    &__ico {
      border-top: 1px solid #222;
      border-right: 1px solid #222;
      width: 10px;
      height: 10px;
    }
    &__ttl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      appearance: none;
      font-size: 2.7rem;
      font-weight: bold;
      text-align: left;
      font-family: inherit;
      color: inherit;
      &[aria-expanded="false"] {
        .c-accordion-box__ico {
          transform: rotate(-45deg);
        }
        & + .c-accordion-box__content {
          display: none;
        }
      }
      &[aria-expanded="true"] {
        .c-accordion-box__ico {
          transform: rotate(-225deg);
        }
        & + .c-accordion-box__content {
          display: block;
        }
      }
      input[type="checkbox"] {
        margin-right: 12px;
      }
    }
    &__content {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $gray;
      @include mq(pc) {
        font-size: 1.4rem;
      }
    }
  }
}

.p-search-keywords {
  .c-accordion-box {
    padding: 15px;
    & + .c-accordion-box {
      margin-top: 15px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .c-accordion-box__ttl {
    font-size: 1.6rem;
    color: $blueRight;
    font-weight : 300;
  }
}