.event-page {
    padding: 78px 0 80px;
    &.event-02 {
        @include mq(pc) {
            padding-bottom: 103px;
        }
        @include mq(sp) {
            padding-bottom: 9rem;
        }
        .c-event-text p:not(:last-child) {
            @include mq(pc) {
                margin-bottom: 28px;
            }
            @include mq(sp) {
                margin-bottom: 2.6rem;
            }
        }
    }
    .color-blue {
        color: $bluePrimary;
    }
    a:hover {
        opacity: 0.85;
    }
    .text-indent {
        text-indent: -5rem;
        margin-left: 5rem;
        display: inline-block;
        @include mq(sp) {
            text-indent: -6rem;
            margin-left: 6rem;
        }
    }
    @include mq(sp) {
        padding: 6rem 0 12rem;
    }
    .c-inner {
        @include mq(pc) {
            padding-left: 70px;
            padding-right: 70px;
        }
        @include mq(sp) {
            padding-left: 5vw;
            padding-right: 5vw;
        
        }
    }
    .c-table {
        &, th, td {
            border: 1px solid #cccccc;
        }
        th {
            padding: 20px 25px;
            width: 50%;
            font-size: 20px;
            background-color: $colorBlue03;
            color: #fff;
            text-align: left;
            @include mq(sp) {
                font-size: 2rem;
                padding: 1.7rem 2rem;
                line-height: 1.75;
            }
        }
        td {
            padding: 30px 22px;
            @include mq(pc) {
                letter-spacing: 0;
            }
            @include mq(sp) {
                font-size: 1.5rem;
                padding: 1.7rem 2.5rem;
            }
            &:nth-child(1) {
                background-color: $gray3rd;
                font-size: 20px;
                font-weight: bold;
                a {
                    color: $bluePrimary;
                }
                @include mq(sp) {
                    font-size: 2rem;
                }
            }
        }
        &:not(:last-child) {
            margin-bottom: 78px;
            @include mq(sp) {
                margin-bottom: 7.8rem;
            }
        }
    }
    .c-event {
        &:not(:last-child) {
            margin-bottom: 84px;
            @include mq(sp) {
                margin-bottom: 6.4rem;
            }
        }
        &-content {
            display: flex;
            align-items: flex-start;
            margin-bottom: 25px;
            @include mq(sp) {
                flex-direction: column-reverse;
                margin-bottom: 2.7rem;
            }
        }
        &-img {
            width: 343px;
            @include mq(pc) {
                min-width: 343px;
                max-width: unset;
            }
            @include mq(sp) {
                width: unset;
                margin-bottom: 3.5rem;
            }
        }
        &-text {
            @include mq(pc) {
                margin-right: 50px;
                line-height: 1.75;
            }
            @include mq(sp) {
                font-size: 1.8rem;
                letter-spacing: 0.075rem;
                line-height: 1.8;
            }
            p {
                &:not(:last-child) {
                    margin-bottom: 17px;
                    @include mq(sp) {
                        margin-bottom: 0.8rem;
                    }
                }
            }
            &--biger {
                @include mq(pc) {
                    font-size: 17px;
                }
            }
        }
        .list-company {
            background-color: $gray3rd;
            padding: 20px;
            @include mq(pc) {
                width: calc(100% - 343px - 40px);
            }
            @include mq(sp) {
                padding: 2.4rem;
            }
            &-title {
                font-weight: bold;
                margin-bottom: 5px;
                @include mq(sp) {
                    margin-bottom: 1rem;
                    font-size: 2rem;
                }
            }
            &-list {
                display: flex;
                flex-wrap: wrap;
                * {
                    @include mq(sp) {
                        font-size: 2rem;
                    }
                }
                li {
                    a {
                        color: $bluePrimary;
                        font-weight: bold;
                        &:hover {
                            opacity: .85;
                        }
                        span {
                            padding: 0 1rem;
                        }
                    }
                }
            }
        }
    }
    .c-head-with-border {
        display: flex;
        align-items: center;
        font-size: 2.7rem;
        margin-bottom: 24px;
        padding-bottom: 20px;
        border-bottom: 2px solid #000;
        width: 100%;
        @include mq(sp) {
            align-items: baseline;
            font-size: 3.2rem;
            margin-bottom: 4.8vw;
            padding-bottom: 2vw;
            letter-spacing: 0.2vw;
            border-bottom: .2rem solid #000;
        }
        img {
            margin-right: 12px;
            @include mq(sp) {
                margin-right: 1.6rem;
                width: 4rem;
                min-width: 4rem;
                height: 4rem;
                position: relative;
                top: 1rem;
            }
        }
        h2 {
            width: 100%;
        }
    }
    .venue {
        margin-top: 47px;
        @include mq(sp) {
            margin-top: 5.5rem;
        }
        .row {
            display: flex;
            border: 1px solid $pre_gray;
            border-left: none;
            border-right: none;
            @include mq(sp) {
                border-width: .1rem;
            }
            .column {
                padding: 20px;
                font-size: 17px;
                font-weight: bold;
                padding-left: 25px;
                @include mq(sp) {
                    font-size: 2rem;
                    padding: 2.5rem;
                }
            }
            .column01 {
                width: 320px;
                background-color: $gray3rd;
                @include mq(sp) {
                    width: 29rem;
                }
            }
        }
    }
}

.en {
    .event-page {
        @include mq(pc) {
            padding-bottom: 120px;
            .c-event-text {
                margin-right: 40px;
            }
            .c-event .list-company {
                width: 100%;
                margin-top: 25px;
            }
        }
    }
}