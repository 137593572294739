
._c-karning {
  letter-spacing: $baseTracking;
}

._c-link-border {
  display: inline-block;
  position: relative;
  &:before {
    content:"";
    position: absolute;
    background: $redRight;
    height: 1px;
    width: 100%;
    bottom: -3px;
    left: 0;
  }
  &--white {
    @extend ._c-link-border;
    &:before {
      background: #fff;
    }
  }
}

._c-link-arrow {
  position: relative;
  padding-right: 17px;
  &:after {
    content:"";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
    border-top: 2px solid $blueRight;
    border-right: 2px solid $blueRight;
    width: 8px;
    height: 8px;
    @include mq(sp) {
      width: 4px;
      height: 4px;
    }
  }
}

.c-table {
  &-dotted {
    border-bottom: 1px dashed #aaaaaa;
  }
  &-line {
    line-height: 2;
    padding: 20px 10px;
    dt {
      flex-basis: 160px;
    }
    dd {
      flex-basis: calc(100% - 160px);
    }
    // @at-root {
    //   .en {
    //     .c-table-line {
    //       @include mq(pc) {
    //         dt {
    //           flex-basis: 200px;
    //         }
    //         dd {
    //           flex-basis: calc(100% - 200px);
    //         }
    //       }
    //     }
    //   }
    // }
    &._2col {
      @include mq(pc) {
          @include fb_width(46.2%);
      }
      @include mq(sp) {
        dt {
          flex-basis: 35%;
        }
        dd {
          flex-basis: calc(100% - 35%);
        }
      }
    }
    &._2col-2 {
      @include mq(pc) {
          @include fb_width(46.2%);
      }
      @include mq(sp) {
        dt {
          flex-basis: 100%;
        }
        dd {
          flex-basis: calc(100% - 35%);
        }
      }
    }
  }
  &-flex {
    line-height: 2;
    padding: 20px 10px;
    // justify-content: space-between;
    justify-content: space-between;
    dt,dd {
      flex-basis: auto;
    }
    dt {
      flex-shrink: 0;
      @include mq(pc) {
        padding-right: 10px;
      }
    }
    &._2col {
      @include mq(pc) {
          @include fb_width(46.2%);
      }
      @include mq(sp) {
        dt {
          flex-basis: 35%;
        }
        dd {
          flex-basis: calc(100% - 35%);
        }
      }
    }
    &._2col-2 {
      @include mq(pc) {
          @include fb_width(46.2%);
      }
      @include mq(sp) {
        dt {
          flex-basis: 100%;
        }
        dd {
          flex-basis: calc(100% - 35%);
        }
      }
    }
  }
}

.list-dotted {
  &:before {
    content: '・';
  }
}

.list-number {
  list-style-type: decimal;
  list-style-position: inside;
}

.c-link-line {
  &__item {
    font-size: 1.7rem;
    display: flex;
    border-top: 1px solid #cccccc;
    &:last-child {
      border-bottom: 1px solid #cccccc;
    }
  }
  &__inner {
    padding: 20px;
    @include mq(sp) {
      padding: 10px;
    }
  }
  &__ico-dl {
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: $transition;
    @include mq(sp) {
      align-items: center;
    }
    &:hover {
      opacity: $opacity;
    }
  }
  ._ico-dl {
    margin-left: 10px;
    width: 26px;
    height: 23px;
    flex-shrink: 0;
    @include mq(sp) {
      width: 37 / $spDesign + vw;
      height: 33 / $spDesign + vw;
      margin-right: 2.6vw;
    }
  }
  &--dotted {
    &__item {
      border-bottom: 1px dashed #aaaaaa;
    }
    &__block {
      @include mq(pc) {
        grid-template:
        "ttl txt"
        / 170px 1fr;
      }
    }
    &__ttl,&__txt {
      padding: 30px 12px;
      @include mq(sp) {
        padding: 15px 12px;
      }
    }
    &__ttl {
      @include mq(pc) {
        grid-area: ttl;
      }
      @include mq(sp) {
        padding-bottom: 0!important;
      }
    }
    &__txt {
      @include mq(pc) {
        grid-area: txt;
      }
    }
  }
}
