.v-acc-list{
    border-bottom: 1px solid $pre_gray;
    padding-bottom: 5.9rem;
  
    &__iteam {
        width: 252px;
        font-size: 1.5rem;
        a{
            padding: 0 16px 0 24px;
            height: 60px;
            justify-content:space-between;
            @include mq(sp){
                height: 7.6rem;
            }
        }
        &:nth-child(1){
            margin-right: 30px;
            @include mq(sp){
                margin-right: 3.3rem;
            }
        }
        @include mq(sp){
            font-size: 1.7rem;
            height: 7.6rem
        }
        div{
            @include mq(sp){
                width: 2.2rem;
            }
        }
    }
    &__info{
        font-size: 1.5rem;
        margin-bottom: 83px;
        @include mq(sp){
            margin-bottom: 8.3rem;
        }
        &:last-child{
            margin-bottom: 12.1rem;
        }
        >ul{
            @include mq(sp){
                flex-direction: column;
            }
        }
    }
    &__en-info{
        &:last-child{
            margin-bottom: 64px;
            @include mq(sp){
                margin-bottom: 7.8rem;
            }
        }
    }
    &__en-info{
        @include mq(sp){
            margin-bottom: 4.1rem;
        }
    }
}
.v-acc-info{
    &__item{
        width: 52rem;
       
        @include mq(sp){
            width: auto;
            img{
                width: 100%;
            }
        }
        &:nth-child(1){
            margin-right: 39px;
            @include mq(sp){
                margin-right: 0px;
            }
        }
        >p{
            &:nth-child(1){
                padding-bottom: 17px;
                border-bottom: 1px solid $pre_gray;
                @include mq(sp){
                    font-size:2.4rem;
                    padding-bottom: 1.5rem;
                }
            }
            &:nth-child(2){
                padding-top: 16px;
                padding-bottom: 23px;
                @include mq(sp){
                    font-size:2rem;
                    padding-top: 1.8rem;
                    padding-bottom: 1.7rem;
                }
            }
        }
        >div{
            margin-top: 15px;
            height: 141px;
            align-items: center;
            @include mq(sp){
                height: 18.2rem;
                margin-top: 2.1rem;
            }
        }
    }
    &__item-cus-en{
        >p:nth-child(2){
            padding-top: 15px;
            @include mq(sp){
                padding-top:1.9rem;
            }
        }
    }
}
.v-acc-text{
        margin-top: 23px;
        margin-bottom: 34px;
        font-size: 1.5rem;
        line-height:2;
   
        @include mq(sp){
            margin-top: 3.8rem;
            margin-bottom: 4rem;
            p:nth-child(1){
                font-size: 2.4rem;
            }
            p:nth-child(2){
                font-size: 2rem;
            }
        }
}
.v-acc-link{
    margin-top: 74px;
    margin-bottom: 60px;
    @include mq(sp) {
        margin-top: 6.1rem;
        margin-bottom: 8.3rem;
    }
    &__list{
        justify-content: space-between;
    }
    ul{
        align-items:flex-end;
        height: 47px;
        @include mq(sp){
            flex-wrap: wrap;
            justify-content: space-between;
            height: auto;
        }
    }
    &__item{
        @include mq(sp) {
            max-width: 32.1rem;
            width: 32.1rem;
            &:nth-child(1){
                margin-right: 3.2rem;
            }
            &:nth-child(3){
                margin-right: 3.2rem;
            }
        }
        &:nth-child(2) {
            @include mq(sp) {
                margin-bottom: 4rem;
            }
        }
        &:nth-child(1) {
            @include mq(sp) {
                margin-bottom: 4rem;
            }
        }
    }
}
.v-acc-cus-mt{
    margin-top: 17px;
}
.v-acc-cus-pt{
    padding-top: 44px;
    display : block;
    @include mq(sp){
        padding-top: 5.5rem;
    }
}
.v-acc-pt{
    @include mq(sp){
        padding-top: 3.9rem;
    }
}

.v-acc-text-details{
    padding-top: 15px;
    font-size: 1.4rem !important;
    white-space: nowrap;
}
.v-acc-cus-width{
    width: 375px;
    @include mq(sp){
        width: 49.2rem;
    }
}

.v-acc-picture {
    &-details {
        position: relative;
        top: 8px;
        @include mq(sp){
            padding-top: 3.6rem !important;
        }
    }
    &-details-p {
        padding-bottom: 3.9rem;
        width: 613px;
        display: block;
        margin: auto;
        @include mq(sp){
            width: auto;
        }
    }

}

.v-acc-map{
    margin-top: 32px;
    height: 39rem;
    @include mq(sp) {
        margin-top: 4rem;
    }
}
.v-acc-center{
    display: block;
    padding-top: 28px;
    @include mq(sp) {
        padding-top: 3.1rem;
    }
}
.v-acc-en-text{
    padding-top: 19px;
    font-size:1.5rem;
    line-height:2;
    @include mq(sp) {
        font-size: 2rem;
        padding-top: 1.6rem;
    }
}