@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 700;
  font-style: normal;
  src: url("../font/NotoSansJP-Bold.otf") format("opentype");
}
@font-face {  
  font-family: 'Noto Sans JP';
  font-weight: 500;
  font-style: normal;
  src: url("../font/NotoSansJP-Medium.otf") format("opentype");
}
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 400;
  font-style: normal;
  src: url("../font/NotoSansJP-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url("../font/Lato-Bold.ttf") format("truetype");
}