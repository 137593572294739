.p-top {
  .c-inner {
    @include mq(pc) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include mq(sp) {
      padding-left: 5.5vw;
      padding-right: 5.5vw;
    }
  }
  &-sec {
    padding-top: 74px;
    padding-bottom: 90px;
    @include mq(sp) {
      padding-top: 8.8vw;
      padding-bottom: 10.8vw;
      .c-heading2 {
        margin-bottom: 2.5vw;
      }
    }
  }
  &-hero {
    position: relative;
    background: url(../img/top/bg_hero_pc.png) no-repeat center top / cover;
    height: 100vh;
    @include mq(sp) {
      background: url(../img/top/img_hero_sp.png) no-repeat top right / 115%
          auto,
        url(../img/top/bg_hero_sp.png) no-repeat center top / cover;
      height: 158vw;
    }
    @include mq(laptop){
      height: 80vh;
    }
    &:after {
      @include mq(pc) {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: url(../img/top/img_hero_pc.png) no-repeat left top;
      }
      @include mq(laptop) {
        height: 100%;
      }
      @include mq(kv) {
        background: url(../img/top/img_hero_sp.png) no-repeat left top /
          cover;
      }
      @at-root {
        .is-tb {
          .p-top-hero:after {
            background-position: right -15vw top;
          }
        }
      }
    }
    &__inner {
      position: absolute;
      bottom: 2.125vw;
      z-index: 2;
      @include mq(sp) {
        bottom: 8.5vw;
      }
    }
    &__catch {
      @include mq(pc) {
        margin-left: 5.2vw;
        width: 47vw;
        min-width: 807px;
      }
      @media screen and (max-width: 1280px) and (min-width: 1024px) {
        width: 670 / (1280 / 100vw);
      }
      @include mq(kv) {
        margin-left: 3.9vw;
        max-width: 664px;
      }
      @include mq(sp) {
        bottom: 49.6vw;
      }
    }
    &__announce {
      margin-top: 51px;
      @include mq(laptop) {
        margin-top: 50px;
      }
      @include mq(sp) {
        margin-top: 6.6vw;
      }
      &-news {
        margin-top: 15px;
        font-size: 4rem;
        padding: 0 1.08vw 0 2.08vw;
        @include mq(pc) {
          max-width: 851px;
        }
        @include mq(sp) {
          padding: 2.4vw 4vw;
          width: 91.86vw;
          font-size: 3.2rem;
        }
        ._bold {
          font-size: 1.425em;
        }
        &-inner {
          display: flex;
          align-items: center;
          @at-root {
            .en {
              .p-top-hero__announce-news {
                @include mq(pc) {
                  display: inline-block;
                  max-width: inherit;
                  padding-top: 0.52vw;
                  padding-bottom: 0.52vw;
                  font-size: 3.4rem;
                }
                @include mq(sp) {
                  font-size: 2.4rem;
                }
                &-inner {
                  display: inline-block;
                }
              }
            }
            .cn {
              .p-top-hero__announce-news {
                font-size: 5.7rem;
                @include mq(sp) {
                  font-size: 4.8rem;
                }
                ._bold {
                  font-size: 0.95em;
                }
              }
            }
          }
        }
      }
      &-basic {
        @include mq(laptop) {
          max-width: 745px;
        }
        @include mq(sp) {
          width: 91.86vw;
        }
      }
      .block-date {
        @include boxDatePC(36);
        font-size: 0.775em;
        @include mq(sp) {
          width: 4vw;
          height: 4vw;
        }
      }
    }
    &__visit {
      position: absolute;
      z-index: 1;
      @include mq(pc) {
        bottom: 1.5vw;
        right: 21px;
      }
      @media screen and (max-width: 1500px) {
        display: flex;
        flex-direction: column;
        top: 48rem;
        right: 2rem;
        width: 16.7rem;
        a:first-child {
          margin-bottom: 0;
        }
      }
      @include mq(sp) {
        top: 20rem;
        a:first-child {
          margin-bottom: 2rem;
        }
      }
      a:hover {
        opacity: 0.9;
      }
    }
  }
  &-picker {
    &__inner {
      background-color: $blueRight;
      border: 1px solid #fff;
      padding: 0;
      height: 62px;
      display: flex;
      align-items: center;
      position: relative;
      @include mq(sp) {
        height: 6.2rem;
        border: .1rem solid #fff;
      }
      &-icon {
        position: absolute;
        left: 86px;
        top: 50%;
        transform: translateY(-50%);
        @include mq(sp) {
          left: 9rem;
          width: 2.4rem;
        }
      }
      .slick-arrow {
        transform: translate(0, 0);
        width: 0;
        height: 0;
        &:before {
          content: '';
          background: url(../img/top/icon-arrow-slide.png) no-repeat;
          background-size: contain;
          position: absolute;
          width: 62px;
          height: 62px;      
          opacity: 1;
          top: 0;
          @include mq(sp) {
            height: 6rem;
            width: 6rem;
          }
        }
        &.slick-prev {
          left: 0;
          top: 0;
          @include mq(sp) {
            left: -1px;
          }
          &:before {
            transform: rotateY(180deg);
            @include mq(sp) {
              // left: 7.5vw;
            }
          }
        }
        &.slick-next {
          right: 60px;
          top: 0;
          @include mq(sp) {
            right: 5.9rem;
          }
          &:before {
            @include mq(sp) {
              // right: 7.5vw;
            }
          }
        }
      }
    }
    &-list {
      color: #fff;
      font-size: 1.7rem;
      width: 100%;
      position: unset;
      @include mq(sp) {
        font-size: 2.4rem;
      }
      .slick-list {
        margin: 0 130px;
        @include mq(sp) {
          margin: 0 13rem;
        }
      }
    }
    &__item {
      @include mq(pc) {
        font-size: 15px;
      }
      &:focus {
        outline: none;
      }
      @include mq(sp) {
        font-size: 1.7rem;
        white-space: nowrap;
        word-break: keep-all;
      }
    }
    &__icon {
      max-width: 24px;
    }
  }
  &__btns {
    margin-top: 50px;
    @include mq(sp) {
      margin-top: 7.8vw;
      display: flex;
    }
    &.c-btn-list {
      @include mq(pc) {
        justify-content: flex-start !important;
        a {
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
      @include mq(sp) {
        justify-content: space-between !important;
      }
    }
  }
  &-jpi {
    .p-top__btns {
      justify-content: center;
    }
    .c-btn-basic__sm {
      @include mq(pc) {
        margin: 0 30px;
      }
    }
  }
  &-past {
    // background: url(../img/top/bg_past_kari.png) no-repeat top center / cover;
    height: 411px;
    position: relative;
    overflow: hidden;
    @include shadowLg-x;
    @include mq(sp) {
      height: 804 / $spDesign + vw;
      background: url(../img/top/pho_poster_sp.jpg) no-repeat center top;
      background-size: cover;
    }
    &:before {
      position: absolute;
      content: "";
      background: url(../img/top/bg_movie.png) repeat 0 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: 8px 8px;
      display: block;
      z-index: 3;
    }
    .c-inner {
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      z-index: 4;
      @include mq(pc) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      @include mq(sp) {
        top: 10vw;
      }
    }
    video {
      @include mq(pc) {
        width: 120%;
        height: 100%;
        object-fit: cover;
      }
      @include mq(sp) {
        display: none;
      }
      @at-root {
        .ie,
        .edge {
          .p-top__movie {
            width: 120%;
            height: auto;
            // display: none;
          }
          // .p-top-past {
          //   background: url(../img/top/pho_poster_pc.jpg) no-repeat top center / cover;
          // }
        }
      }
    }
  }
  &-play {
    font-size: 3.6rem;
    display: flex;
    align-items: center;
    letter-spacing: 2.2px;
    @include mq(sp) {
      justify-content: center;
      margin-top: 6vw;
      font-size: 3.6rem;
    }
    &__ico {
      margin-left: 29px;
      font-weight: 600;
      width: 98px;
      height: 98px;
      @include mq(sp) {
        width: 13vw;
        height: 13vw;
        margin-left: 3.86vw;
      }
    }
  }
  &-intro {
    // &:before {
    //   content: "";
    //   background: url(../img/bg_dotted.png) repeat 0 0;
    //   width: 79.6vw;
    //   height: calc(100% - 50px);
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   z-index: -1;
    //   @include mq(sp) {
    //     background-size: 6%;
    //   }
    // }
    .c-btn-basic {
      &:nth-child(3) {
        @include mq(pc) {
          margin-top: 30px;
        }
      }
    }
  }
  &-about {
    padding-top: 180px;
    position: relative;
    @include mq(pc) {
      display: flex;
      background: url(../img/top/bg_tokyopack.png) no-repeat left top 60px /
        1920px 154px;
    }
    @include mq(sp) {
      padding-top: 24vw;
      background-size: auto 12.2vw;
      background-position: left 5.3vw;
      &:before {
        position: absolute;
        content: "";
        background-color: rgba($colorPrimary, 0.03);
        width: 675 / (750 / 100vw);
        height: calc(100% - 36.4vw);
        right: 0;
        top: 36.4vw;
        z-index: -1;
      }
      &:after {
        position: absolute;
        content: "";
        background: url(../img/top/bg_tokyopack.png) no-repeat left 5.3vw / auto
          12.4vw;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
      }
    }
    &__photo {
      background: url(../img/top/pho_sec01_pc.jpg) no-repeat top center / cover;
      @include mq(pc) {
        height: 600px;
        @include fb_width(50%);
      }
      @include mq(sp) {
        width: 675 / (750 / 100vw);
        height: 463 / (750 / 100vw);
      }
    }
    &__summary {
      position: relative;
      z-index: 2;
      padding: {
        left: 73 / (1920 / 100vw);
        top: 160 / (1920 / 100vw);
        right: 75 / (1920 / 100vw);
      }
      // @media screen and (max-width:1660px) and (min-width: 769px){
      //   margin-left: auto;
      // }
      @include mq(pc) {
        @include fb_width(50%);
      }
      @include mq(sp) {
        margin-top: 0;
        padding: 12vw 4vw;
        margin-right: 0;
      }
      &:after {
        @include mq(pc) {
          position: absolute;
          content: "";
          background: rgba($colorPrimary, 0.16);
          width: calc(100% - 10.05vw);
          height: 546px;
          background-size: contain;
          top: 70 / (1920 / 100vw);
          right: 0;
          z-index: -1;
        }
      }
    }
  }
  &-guide {
    padding: 70px 0 80px;
    &-list {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }
      &__item {
        position: relative;
        padding: 60px 0 40px;
        text-align: center;
        border-radius: 8px;
        @include shadowSm;
        @include mq(pc) {
          @include fb_width(374px);
        }
        @include mq(sp) {
          display: block;
          padding: 8vw 0;
          &:nth-child(n + 2) {
            margin-top: 40 / (750 / 100vw);
          }
        }
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 16px 16px;
          border-color: transparent transparent $colorPrimary transparent;
          right: 10px;
          bottom: 10px;
        }
        p {
          margin-top: 38px;
          font-size: 2.3rem;
          font-weight: bold;
          color: $colorPrimary;
          line-height: 1;
          @include mq(sp) {
            margin-top: 6.13vw;
            font-size: 3.9rem;
          }
        }
        .ico-guide-01 {
          width: 90px;
          height: 90px;
        }
        .ico-guide-02 {
          width: 82px;
          height: 98px;
        }
        .ico-guide-03 {
          width: 98px;
          height: 90px;
        }
      }
    }
  }
  &-past {
    @include mq(sp) {
      // margin-top: 16vw;
    }
    &__box {
    }
  }
  ._c-paragraph {
    line-height: 2;
    @include mq(sp) {
      font-size: 2rem;
      line-height: 1.7;
      letter-spacing: 0.1rem;
    }
  }
  &-jpi {
    .c-inner {
      @include mq(pc) {
        padding-left: 40px;
        .c-heading2 {
          margin-bottom: 40px;
        }
      }
    }
    .c-heading2 {
      @include mq(sp) {
        font-size: 3.2rem;
      }
    }
  }
  &-seminar-event {
    background-color: $gray3rd;
    padding: 105px 0 80px;
    @include mq(sp) {
      padding: 12rem 0 9rem;
    }
  }
  &-seminar {
    margin-bottom: 105px;
    @include mq(sp) {
      margin-bottom: 12rem;
    }
    &__title {
      margin-bottom: 74px;  
      @include mq(sp) {
        margin-bottom: 6rem;
      }
      &-sub {
        font-size: 32px;
        font-weight: bold;
        @include mq(sp) {
          font-size: 3.2rem;
        }
      }
      &-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq(sp) {
          flex-direction: column;
          align-items: flex-start;
        }
        p {
          font-size: 48px;
          font-weight: bold;
          @include mq(sp) {
            font-size: 4.8rem;
            margin-bottom: 3.5rem;
          }
        }
      }
    }
    &__main {
      padding-bottom: 50px;
      @include mq(sp) {
        margin-bottom: 35rem;
      }
      &::before {
        content: "";
        background: url(../img/top/seminar-pic.jpg) no-repeat;
        background-position: right;
        position: absolute;
        left: 0;
        width: 50%;
        @include mq(pc) {
          height: 600px;
        }
        @include mq(sp) {
          width: 100%;
          height: 44.2rem;
          background: url(../img/top/seminar-pic_sp.jpg) no-repeat;
          background-size: cover;      
        }
      }
      &-list {
        width: 647px;
        margin-left: auto;
        background-color: #fff;
        padding: 12px 40px;
        box-shadow: 4.5px 7.8px 14.8px 3.2px rgba(51, 51, 51, 0.12);
        z-index: 1;
        position: relative;
        top: 50px;
        @include mq(sp) {
          width: 100%;
          padding: .8rem 4rem;
          box-shadow: .45rem .78rem 1.48rem .32rem rgba(51, 51, 51, 0.12);
          top: 35rem;
        }
      }
      &-item {
        display: flex;
        align-items: center;
        padding: 24px 16px;
        background: url(../img/top/circle-pink-02.png) no-repeat;
        background-size: 16px;
        background-position: 97% center;
        &:hover {
          opacity: .85;
        }
        @include mq(sp) {
          padding: 3.2rem 1.7rem;
          background-size: 2.2rem;
          background-position: 98% center;
        }
        &:not(:last-child) {
          border-bottom: 2px solid #cccccc;
          @include mq(sp) {
            border-bottom: .2rem solid #cccccc;
          }
        }
      }
      &-icon {
        margin-right: 17px;
        @include mq(sp) {
          margin-right: 1.7rem;
          width: 4rem;
          height: auto;
        }
      }
      &-text {
        font-size: 18px;
        font-weight: bold;
        @include mq(sp) {
          font-size: 2rem;
        }
      }
    }
  }
  &-event {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mq(sp) {
        flex-direction: column;
      }
    }
    &__item {
      width: 48.3%;
      margin-bottom: 40px;
      &:hover {
        opacity: 0.85;
      }
      @include mq(sp) {
        width: 100%;
        margin-bottom: 3.8rem;
      }
      &-text {
        padding: 11px;
        background-image: linear-gradient(to right, #01294e, #154f85);
        color: #fff;
        text-align: center;
        font-size: 18px;
        @include mq(sp) {
          font-size: 2.4rem;
          padding: 1.5rem;
        }
      }
    }
  }
}
.p-top-corona {
  padding: 80px 0;
  background: url(../img/top/corona-bg.jpg) no-repeat;
  background-size: auto 100%;
  @include mq(sp) {
    padding: 5.5rem 0;
  }
  .c-inner {
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @include mq(sp) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .c-btn-basic {
    @media screen and (max-width:768px) {
      margin-top: 25px;
    }
  }
  &__pdf {
    background-color: rgba($colorParagraph, .8);
    color: #fff;
    width: 100%;
    text-align: center;
    box-shadow: -3.8px 1.4px 7.7px 0.3px rgba(0, 0, 0, 0.32);
    @include mq(pc) {
      padding: 30px 30px 40px;
      margin-bottom: 40px;
    }
    @include mq(sp) {
      padding: 3rem 4rem 4rem;
      margin-bottom: 6rem;
    }
    &-btn {
      background-color: $blueRight;
      display: inline-block;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: .9;
      }
      span {
        color: #fff;
      }
      @include mq(pc) {
        border-radius: 4px;
        font-size: 15px;
        width: 528px;
        height: 67px;
      }
      @include mq(sp) {
        width: 90%;

        padding: 1.5rem 0;
        border-radius: .4rem;
        font-size: 2.4rem;
      }
    }
  }
  &__heading {
    font-size: 3.2rem;
    font-weight: bold;
    @include mq(pc) {
      margin-bottom: 25px;
    }
    @include mq(sp) {
      font-size: 2.4rem;
      margin-bottom: 3rem;
    }
  }
  &__registInfo {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 100%;
    @include mq(sp) {
      flex-direction: column;
    }
    .right {
      width: 50%;
      @include mq(pc) {
        padding: 30px 60px;
      }
      @include mq(sp) {
        padding: 3rem 3.2rem;
        width: 100%;
      }
    }
    &--title {
      font-weight: bold;
      @include mq(pc) {
        font-size: 27px;
        margin-bottom: 11px;
      }
      @include mq(sp) {
        font-size: 3.2rem;
        margin-bottom: 3rem;
      }
    }
    &--content {
      @include mq(pc) {
        font-size: 17px;
        margin-bottom: 45px;
      }
      @include mq(sp) {
        font-size: 2rem;
        margin-bottom: 4rem;
      }
    }
  }
  &__exhibitors {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include mq(pc) {
      margin-top: 60px;
    }
    @include mq(sp) {
      margin-top: 6rem;
      flex-wrap: wrap;
    }
    &-item {
      width: 31%;
      border-radius: 4px;
      background-image: linear-gradient(to right, #01294e, #154f85);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq(pc) {
        padding: 12px;
        height: 145px;
      }
      @include mq(sp) {
        border-radius: .4rem;
        padding: 1.2rem;
        height: 19.2rem;
        width: 48%;
        &:nth-child(1) {
          width: 100%;
          margin-bottom: 2.4rem;
          &::before {
            width: 95%;
            height: 83%;
          }
        }
      }
      &::before {
        content: "";
        width: 91%;
        height: 80%;
        position: absolute;
        border-radius: 4px;
        border-width: 0px;
        transition: all .3s ease;
        top: 12px;
        left: 12px;
        @include mq(sp) {
          width: 90%;
          height: 83%;
          border-radius: .4rem;
          border: .4rem solid #fff;
          top: 1.2rem;
          left: 1.2rem;
        }
      }
      &:hover {
        &::before {
          border: 3px solid #fff;
          @include mq(sp) {
            border-width: .4rem;
          }
        }
      }
      &:not(:first-child) {
        @include mq(sp) {
          p {
            font-size: 2.7rem;
          }
        }
      }
      p {
        color: #fff;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;
        @include mq(pc) {
          font-size: 24px;
        }
        @include mq(sp) {
          font-size: 3.2rem;
          border-radius: .4rem;
        }
      }
    }
  }
}

.p-top-c-btn--pink {
  background: url(../img/top/circle-pink.png) no-repeat;
  background-position: calc(100% - 17px);
  background-color: $colorPrimary;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;  
  border-radius: 4px;
  &:hover {
    opacity: .9;
  }
  @include mq(pc) {
    height: 60px;
    background-size: 16px 16px;
  }
  @include mq(sp) {
    background-position: 97%;
    border-radius: .4rem;
    height: 7.6rem;
    background-size: 2.2rem;
    font-size: 2.4rem;
  }
  &.p-top-c-btn--small {
    width: 252px;
    justify-content: flex-start;
    padding-left: 24px;
    @include mq(sp) {
      background-position: 94%;
      width: 32.1rem;
      padding-left: 3.2rem;
    }
  }
  &.p-top-c-btn--blue {
    background-image: url(../img/top/circle-blue.png);
    background-color: $blueRight;
  }
}

.p-top-corona-en {
  padding: 40px 0;
  background: #eeeeee;
}
@media screen and (max-width: 768px) {
  .p-top-corona-en {
    padding: 40px 0; 
  }
}
.p-top-corona-en .c-inner {
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .p-top-corona-en .c-inner {
    flex-direction: column;
    justify-content: center;
  }
}

.en {
  @include mq(pc) {
    .p-top-corona__registInfo--title {
      font-size: 40px;
    }
    .p-top-jpi .c-inner {
      padding-left: 30px;
    }
    .p-top-sec {
      padding-top: 64px;
      padding-bottom: 79px;
    }
    .p-top-jpi .c-inner .c-heading2 {
      margin-bottom: 20px;
    }
    &.p-top ._c-paragraph {
      font-size: 16px;
    }
    .p-top__btns {
      margin-top: 40px;
    }
  }
  @include mq(sp) {
    .p-top-corona__registInfo--title {
      text-align: center;
    }
    .p-top-seminar__main {
      margin-bottom: 25rem;
    }
    .p-top-c-btn--pink.p-top-c-btn--small {
      padding-left: 2rem;
      font-size: 2.2rem;
    }
    .p-top-corona__heading {
      font-size: 2.4rem;
    }
    .p-top-hero__announce {
      margin-top: 3.6vw;
    }
    .p-top-hero__catch {
      margin-left: 1vw;
      width: 99%;
    }
  }
}

.cn {
  .p-top-corona {
    padding-bottom: 0;
    background-size: cover;
    @include mq(sp) {
      margin-bottom: -9rem;
    }
  }
  .p-top__btns a {
    box-shadow: 3.9px 4.6px 15.3px 2.7px rgba(47, 47, 47, 0.16);
  }
  .p-top-corona__pdf {
    z-index: 10;
    position: relative;
    @include mq(sp) {
      padding: 4rem 2rem;
    }
  }
  .p-top-about {
    padding: 180px 0 152px 0;
    background: none;
    &:after {
      background: none;
    }
    @include mq(sp) {
      padding: 0;
      padding-top: 18rem;
      margin-bottom: 9rem;
    }
  }
  .p-top-jpi .p-top__btns {
    justify-content: center !important;
  }
  .p-top-about .p-top ._c-paragraph {
    @include mq(sp) {
      font-size: 3rem;
    }
  }
  .p-top__btns.c-btn-list {
    @include mq(sp) {
      flex-direction: column;
    }
  }
  .p-top-corona__registInfo {
    position: relative;
    &:hover {
      opacity: 0.85;
    }
    .right {
      @include mq(sp) {
        position: relative;
      }
    }
    .p-top-c-btn--pink {
      position: absolute;
      height: 100%;
      width: 61px;
      right: 0;
      top: 0;
      background-position: center;
      border-radius: 0;
      @include mq(sp) {
        width: 7.6rem;
      }
      &.pc {
        @include mq(sp) {
          display: none;
        }
      }
    }
    &--title {
      font-size: 32px;
      @include mq(sp) {
        font-size: 3.2rem;
        margin-bottom: 0;
      }
    }
  }
  
  @include mq('laptop') {
    .p-top-play {
      position: relative;
      bottom: 50px;
    }
    .p-top-past .c-inner {
      top: 120px;
    }
  }
  @include mq(pc) {
    .p-top-hero__catch {
      margin-left: 3vw;
    }
    .p-top-hero__announce {
      margin-top: 105px;
    }
    .p-top-corona {
      padding: 60px 0;
    }
    .p-top-about__heading {
      margin-bottom: 16px;
    }
    .p-top__btns {
      margin-top: 40px;
    }
    .p-top-hero__visit {
      bottom: 1.5vw;
      top: unset;
    }
  }
  @include mq(sp) {
    .p-top-corona{
      padding: 4rem 0;
    }
    .p-top-corona__heading {
      margin-bottom: 2rem;
    }
    .p-top-corona__pdf-btn {
      width: 85%;
    }
    .p-top-about__summary {
      padding: 7vw 4vw;
    }
    .p-top-about ._c-paragraph {
      font-size: 3rem;
      letter-spacing: 0.3vw;
      line-height: 2.1;
    }
    .p-top-about .c-heading2 {
      margin-bottom: 3vw;
      font-size: 4.5rem;
    }
    .p-top-past {
      height: 94vw;
    }
    .p-top .p-top-sec ._c-paragraph {
      line-height: 2.7;
      letter-spacing: 0.5rem;
    }
  }
}

.ie {
  @include mq(pc) {
    .p-top-picker-list {
      position: static;
    }
    .p-top-picker__inner .slick-arrow {
      width: 62px;
      height: 62px;
      right: 0;
    }
  }
}