.p-feature {
  &-intro {
    margin-top: 60px;
    @include mq(sp) {
      margin-top: 8vw;
    }
  }
  &-boxs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq(pc) {
      margin-top: 50px;
    }
  }
  &-box {
    border: 1px solid #cccccc;
    border-radius: 18px;
    text-align: center;
    padding: 30px;
    &._1col {
      @include mq(pc) {
        flex-shrink: 1;
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &._2col {
      @include mq(pc) {
        flex-basis: calc(calc(100% - 30px) / 2 );
        max-width: 525px;
      }
    }
    @include mq(sp) {
      margin-top: 8vw;
      padding: 6.6vw 4vw;
    }
    ._c-paragraph {
      display: inline-block;
      text-align: left;
    }
    &__figure {
      margin-bottom: 30px;
      @include mq(sp) {
        margin-bottom: 6vw;
      }
    }
    .c-heading3 {
      margin-bottom: 30px;
      @include mq(pc) {
        font-size: 2.2rem;
      }
      @include mq(sp) {
        margin-bottom: 6vw;
      }
    }
    &:nth-child(n+2) {
      @include mq(pc) {
        margin-top: 30px;
      }
    }
  }
}