@charset "utf-8";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  letter-spacing: $baseTracking;
  @include mq(sp) {
    font-size: calc(100vw / 75);
  }
}

body {
  width: 100%;
  // font-family: $mincho;
  line-height: 1.5;
  color: $colorParagraph;
  // background-color: $white;
  word-wrap: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  position: relative;
  font-size: 1.5rem;
  @include mq(pc) {
    overflow-x: hidden;
  }
  @include mq(sp) {
    min-width: auto;
    font-size: 2.7rem;
  }

}

a {
  color: $colorParagraph;
  text-decoration: none;
  transition: all .3s;
  // &:hover,
  // &:active,
  // &:focus {
  //   opacity: .5;
  // }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

small {
  font-size: 1em;
}

// IE11 SVGサイズ崩れハック
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$='.svg'] {
    width: 100%;
    height: auto;
  }
}
.sp {
  display: none;
  @include mq(sp) {
    display: block;
  }
}

.pc {
  @include mq(sp) {
    display: none;
  }
}

.c-inner {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  @include mq(sp) {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

._c-paragraph {
  font-size: 1.7rem;
  line-height: 2;
  letter-spacing: $baseTracking;
  @include mq(sp) {
    font-size: 2.7rem;
  }
}