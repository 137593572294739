.c-heading {
  &2 {
    margin-bottom: 46px;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.75;
    @include mq(sp) {
      margin-bottom: 5.6vw;
      font-size: 4.5rem;
    }
  }
  &3 {
    font-size: 2.4rem;
    margin-bottom: 10px;
    @include mq(sp) {
      font-size: 3.9rem;
    }
  }
  &-hasbox {
    margin-bottom: 20px;
    font-size: 1.7rem;
    padding: 10px;
    @include mq(sp) {
      font-size: 3rem;
    }
    &.-has-check {
      padding-left: 48px;
      background-image: url(../img/ico_check_white.png);
      background-repeat: no-repeat;
      background-position: center left 12px;
    }
  }
  &-hasborder {
    &2 {
      font-size: 2.7rem;
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: 2px solid #000;
      @include mq(sp) {
        font-size: 4rem;
      }
    }
  }
}

.list-cmn-ttl {
  padding: 8px 8px 8px 33px;
  font-size: 1.4rem;
  background: $blueRight url('../img/ico_list_check_01.png') 9px center no-repeat;
  font-weight : 500;
  color: #ffffff;
  &-add {
    font-size: 1.2rem;
  }
}