// .search {
//   &-sec {
//     margin-top: 80px;
//     &:nth-of-type(n+2) {
//       margin-top: 50px;
//     }
//     &-lead {
//       line-height: 1.6;
//     }
//   }
// }

.search-area__lead {
  line-height: 1.4;
  span {
    font-size: 2.4rem;
    @include mq(sp) {
      font-size: 2.8rem;
    }
  }
}

.search-area {
  padding: 40px;
  background: $searchColor;
  @include mq(sp) {
    padding: 20px;
  }
  .c-btn-basic {
    @include mq(sp) {
      width: 100%;
    }
  }
}

.search-box {
  width: 100%;
  height: 50px;
}

.p-search-keywords__button {
  margin: 25px auto 0;
  justify-content: center;
}



.list-cmn-summary-box {
  margin-top: 20px;
}

.category-check-list {
  margin-top: 20px;
  li {
    & + li {
      margin-top: 10px;
    }
  }
  label {
    &:hover {
      cursor: pointer;
    }
  }
}

.min-btn {
  text-align: right;
  margin-top: 10px;
}

.min-btn-item {
  position: relative;
  padding: 5px 20px 5px 45px;
  display: inline-block;
  border: 1px #ccc solid;
  font-size: 1.1rem;
  color: #333;
  line-height: 1.3;
  &::before {
    content: "";
    position: absolute;
    background: url('../img/ico_pdf_02.png') no-repeat 0 0;
    width: 13px;
    height: 16px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
  }
  &:hover {
    opacity: $opacity;
  }
}

.p-search-keywords {
  .c-accordion-box__content {
    label {
      &:hover {
        cursor: pointer;
      }
    }
    input {
      border: 1px solid #ccc;
    }
  }
  .c-accordion-box__ttl {
    input {
      border: 1px solid #ccc;
    }
  }
  .category-check-list {
    input {
      border: 1px solid #ccc;
    }
  }
}


.pager {
  text-align: center;
  font-size: 0;
  @at-root {
    .result {
      .pager {
        margin-top: 30px;
      }
    }
  }
  &-items {
    display: inline-block;
    padding: 6px 11px;
    border: 1px solid #dddddd;
    color: #333;
    font-size: 1.4rem;
    line-height: 1.5;
    &:hover {
      cursor: pointer;
    }
    &.extra-items {
      border: none;
    &:hover {
      cursor: auto;
    }
    }
    &:nth-child(n+2) {
      margin-left: 9px;
    }
    &.current {
      color: #fff;
      background: #0d4098;
    }
  }
  & + .result-data {
    margin-top: 30px;
  }
}

.result-data {
  & + .pager {
    margin-top: 30px;
  }
  .c-btn-basic {
    margin: 30px auto 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 169px;
    height: 39px;
    line-height: 1;
    font-size: 14px;
    @include mq(sp) {
      position: static;
      width: 100%;
    }
  }
}

.result-box {
  padding: 24px 0 24px 20px;
  background: $searchColor;
}

.result-box-catch {
  margin: 0;
  padding: 0;
  font-size: 2.7rem;
  font-weight: bold;
}

.p-search-results {
  .list-about-category-box-link {
    float: none;
  }
  .c-btn-basic__sm {
    @include mq(pc) {
      max-width: 230px;
      flex-basis: 230px;
    }
  }
}