.p-guide {
  .c-lower-wrap__container-inner {
    @include mq(sp) {
      margin-top: 0;
    }
  }
  // &-primaryFigure {
  //   grid-template-columns: 430px 1fr;
  //   -ms-grid-columns: 430px 1fr;
  //   width: 100%;
  //   &._2row {
  //     grid-gap: 10px;
  //     gap: 10px;
  //     grid-template-rows: 80px 80px;
  //     -ms-grid-rows: 80px 80px;
  //     grid-template:
  //     "grid-01 grid-02" 80px
  //     "grid-03 grid-04" 80px
  //     / 430px 1fr;
  //   }
  //   .grid-small {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     grid-column: 1 / 2;
  //     // -ms-grid-column: 1;
  //     // -ms-grid-column-span: 2;
  //   }
  //   .grid-big {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     grid-column: 2 / 4;
  //     -ms-grid-column: 2;
  //     -ms-grid-column-span: 4;
  //     background: #ffffff;
  //   }
  //   .grid-01,.grid-02 {
  //     grid-row: 1;
  //     -ms-grid-row: 1;
  //   }
  //   .grid-03,.grid-04 {
  //     grid-row: 2;
  //     -ms-grid-row: 2;
  //   }
  //   .grid-01 {
  //     grid-area:grid-01;
  //   }
  //   .grid-02 {
  //     grid-area:grid-02;
  //   }
  //   .grid-03 {
  //     grid-area:grid-03;
  //   }
  //   .grid-04 {
  //     grid-area:grid-04;
  //   }
  // }
  &-tab02 {
    &__summary {
      margin-top: 60px;
      font-size: 4rem;
      text-align: center;
    }
    &__fee {
      font-size: 4rem;
    }
  }
  &-tab03-sec {
    &__intro {
    }
    &__btn {
      margin: 40px auto 0;
    }
  }
}

.p-guide {
  .c-heading-hasbox {
    @include mq(pc) {
      margin-bottom: 40px;
    }
    @include mq(sp) {
      margin-top: 40px;
    }
  }
  &-txt-anchor {
    display: flex;
    @include mq(pc) {
      font-size: 1.8rem;
    }
    @include mq(sp) {
      flex-wrap: wrap;
      margin-left: -10px;
    }
    a {
      display: block;
      @include mq(sp) {
        padding: 10px 20px 10px 15px;
      }
      &:nth-child(n+2) {
        margin-left: 54px;
        @include mq(sp) {
          margin-left: 0;
        }
      }
    }
  }
  &-note {
    font-size: 1.3rem;
    @include mq(sp) {
      font-size: 1.8rem;
    }
  }
  &-basicKoma {
    &__size {
      max-width: 541px;
      flex-basis: 541px;
      @include mq(pc) {
        margin-left: 16px;
      }
    }
    &-info {
      &__box {
        margin-top: 16px;
        padding: 23px;
        border: 1px solid $gray;
        @include mq(sp) {
          padding: 15px;
        }
      }
    }
    &__note {
      margin-top: 40px;
      border: 1px solid $gray;
      @include mq(sp) {
        margin-top: 20px;
      }
    }
  }
  &-cmn-borderBox {
    border: 1px solid $gray;
    padding: 15px 40px;
    @include mq(sp) {
      padding: 15px;
    }
  }
  &-waribiki {
    font-size: 3.2rem;
    @include mq(sp) {
      font-size: 4.2rem;
    }
    &__item {
      text-align: center;
      line-height: 1.4;
      @include mq(pc) {
        flex-basis: 400px;
        max-width: 400px;
      }
      @include mq(sp) {
        &:nth-child(n+2) {
          margin-top: 20px;
        }
      }
    }
    &__box {
      // margin-top: 10px;
      padding: 0 0 20px;
      box-shadow: 6px 9px 9px 1px rgba(97, 97, 97, 0.08);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include mq(pc) {
        height: auto;
      }
      @include mq(sp) {
        padding: 0 10px 20px;
      }
    }
  }
  &-date-box {
    grid-gap: 6px;
    font-size: 2.4rem;
    font-weight: bold;
    &._1col {
      grid-template:
      "item1 item2" 80px
      / 1fr 1.5fr;
      @include mq(sp) {
        grid-template:
        "item1 item2" 50px
        / 1fr 1.5fr;
      }
    }
    &._2col {
      grid-template:
      "item1 item2 " 80px
      "item3 item4" 80px
      / 1fr 1.5fr;
      @include mq(sp) {
        grid-template:
        "item1 item2" 50px
        "item3 item4" 50px
        / 1fr 1.5fr;
      }
    }
    &._3col {
      grid-template:
      "item1 item2 " 80px
      "item3 item4" 80px
      "item5 item6" 80px
      / 1fr 1.5fr;
      @include mq(sp) {
        grid-template:
        "item1 item2" 50px
        "item3 item4" 50px
        "item5 item6" 50px
        / 1fr 1.5fr;
      }
    }
    // &._2col {
    //   grid-template:
    //   "item1 item2" 80px
    //   "item3 item4" 80px
    //   / 1fr 1.5fr;
    //   @include mq(sp) {
    //     grid-template:
    //     "item1 item2" 50px
    //     "item3 item4" 50px
    //     / 1fr 1.5fr;
    //   }
    // }
    &.p-guide-fee {
      grid-template:
      "item1 item2" 50px
      "item3 item4" 50px
      "item5 item6" 50px
      / 1fr 1.5fr;
      grid-template-columns: 1fr 1.5fr;
      grid-template-rows: 50px 50px;
      @include mq(sp) {
        grid-template:
        "item1 item2 " 50px
        "item3 item4" 50px
        "item5 item6" 50px
          / 1fr 1.5fr;
      }
    }
    &__item {
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        @include mq(sp) {
          padding: {
            left: 5px;
            right: 5px;
          }
        }
      }
    }
    .grid-item01 {
      grid-area: item1;
    }
    .grid-item02 {
      grid-area: item2;
    }
    .grid-item03 {
      grid-area: item3;
    }
    .grid-item04 {
      grid-area: item4;
    }
    .grid-item05 {
      grid-area: item5;
    }
    .grid-item06 {
      grid-area: item6;
    }
  }
  &-fee {
    font-weight: normal;
    font-size: 1.8rem;
  }
  &-book {
    padding: 30px 40px 40px;
    border: solid 4px $blueRight;
    @include mq(sp) {
      padding: 20px 10px;
    }
    &__catch {
      font-size: 4.8rem;
      font-weight: bold;
    }
    .c-btn-list {
      margin-top: 30px;
    }
  }
  &-forMore {
    border: 4px solid $blueRight;
    dt {
      padding: 15px 0;
      font-size: 2.7rem;
    }
    dd {
      padding: 25px 10px;
    }
  }
  &-faciilties {
    &-table {
      font-size: 2.4rem;
      width: 100%;
      th,td {
        text-align: center;
        border: 1px solid #cccccc;
        height: 80px;
        vertical-align: middle;
        font-weight: normal;
      }
      td {
        font-size: 1.3rem;
      }
    }
  }
  &-cntable {
    margin-top: 20px;
    width: 100%;
    @include mq(sp) {
      width: 120%;
    }
    th,td {
      vertical-align: middle;
      text-align: center;
      border: 1px solid #333333;
      @include mq(sp) {
        min-width: 150px;
      }
    }
  }
}
