.reg-gr{
    .reg-gr-iteam{
        border-top: 1px solid $pre_gray;
      
        &:last-child{
            border-bottom: 1px solid $pre_gray;
        }

        div:nth-child(1){
            width: 320px;
            font-size: 1.7rem;
            background-color: $gray3rd;
            padding:17px 0 23px 17px;
            @include mq(sp){
                padding:3rem 0 3rem 2.5rem;
                width: 100%;
                max-width: 28.9rem;
                min-width: 28.9rem;
                width: 28.9rem;
                font-size: 2rem;
            }
        }
        div {
            padding-left: 26px;
            padding:23px 0 23px 26px;
            @include mq(sp){
                padding:3rem 0 3rem 2.5rem;
            }
            p:nth-child(1){
                font-size: 2rem;
            }
        }
        &--f14{
            font-size: 1.4rem;
        }
        &--f13{
            font-size: 1.3rem;
        }
        &--fpc14-fsp15{
            font-size: 1.4rem;
            @include mq(sp) {
                font-size: 1.5rem;
            }
        }
    }
    .v-reg-box{
        padding-left: 140px;
        padding-right: 140px;
        @include mq(sp) {
            padding-left: 10.8rem;
            padding-right: 10.8rem;
        }
        &__child{
            padding-top: 18px;
            padding-bottom: 18px;
            margin-bottom: 1.5rem;
            @include mq(sp) {
                padding-top: 2.3rem;
                padding-bottom: 2.4rem;
            }
            p:nth-child(1){
                font-size: 2rem;
            }
        }
    }
    .v-reg-line{
        margin-top: 24px;
        margin-bottom: 60px;
        @include mq(sp) {
            margin-top: 3.2rem;
            margin-bottom: 4rem;
        }
    }
}